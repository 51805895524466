
import {Component, Watch} from 'vue-property-decorator';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import SecondaryLineBarChartFilterable from '@/components/reports-v2/components/filterables/SecondaryLineBarChartFilterable.vue';
import TiarcoChemicalMYearlyComparison from '@/components/reports-v2/components/codedWidgets/customisation/TiarcoChemicalMYearlyComparison.vue';
import TiarcoChemicalMMonthlyComparison from '@/components/reports-v2/components/codedWidgets/customisation/TiarcoChemicalMMonthlyComparison.vue';
import TiarcoChemicalMOtherIncomeBreakdownTable from '@/components/reports-v2/components/codedWidgets/customisation/TiarcoChemicalMOtherIncomeBreakdownTable.vue';
import TiarcoYearlySalesAnalysisByItemCode from '@/components/reports-v2/components/codedWidgets/customisation/TiarcoYearlySalesAnalysisByItemCode.vue';

@Component({
  components: {
      TiarcoYearlySalesAnalysisByItemCode,
    TiarcoChemicalMOtherIncomeBreakdownTable,
    TiarcoChemicalMMonthlyComparison,
    TiarcoChemicalMYearlyComparison,
    SecondaryLineBarChartFilterable,
  },
})

export default class TiarcoChemicalDashboard extends FilterWidget {

}
