
import {Component, Watch} from 'vue-property-decorator';
import DataInfo from '@/components/reports-v2/components/elements/DataInfo.vue';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Row from 'primevue/row';
import InputText from 'primevue/inputtext';
import BaseFilterable from '@/components/reports-v2/components/filterables/BaseFilterable.vue';
import {FilteredDatabase} from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import gdbx from '@/store/modules/gdbx';
import {nestedGroupBy} from '@/util/nestedGroupBy';
import moment from 'moment';
import {FilterMatchMode} from 'primevue/api';
import {PermissionsGroup} from '@/store/models.def';
import settingx from '@/store/modules/settingx';

@Component({
  components: {
    DataTable,
    Column,
    ColumnGroup,
    Row,
    Dropdown,
    InputText,
    Button,
    DataInfo,
    BaseFilterable,
  },
})

export default class VivalecDashboard extends FilterWidget {

  public get permissionIds(): PermissionsGroup[] {
    return ['sales'];
  }

  public get exportFileName() {
    const formattedDate = moment(this.selectedAsOfDate).format('DD MMM YY');
    return 'Stock Item Budgeting ' + 'As Of ' + formattedDate;
  }

  public get dateFormatted() {
    return moment(this.selectedDateRange[0]).format('DD MMM YYYY')
    !== moment(this.selectedDateRange[1]).format('DD MMM YYYY')
        ? `${moment(this.selectedDateRange[0]).format('DD MMM YYYY')} - ${moment(
            this.selectedDateRange[1],
        ).format('DD MMM YYYY')}`
        : `As of ${moment(this.selectedDateRange[0]).format('DD MMM YYYY')}`;
  }

  public get dateFormatted2() {
    return `As of ${moment(this.selectedDateRange[1]).format('DD MMM YYYY')}`;
  }

  public get expensiveHook() {
    const { selectedAsOfDate, selectedAgents, selectedSuppliers } = this;
    return JSON.stringify([
      selectedAsOfDate,
      selectedAgents,
      selectedSuppliers,
    ]);
  }

  public get date() {
    return this.selectedDateRange;
  }

  public get dateRange() {
    return this.selectedDateRange;
  }

  public get currencySymbol() {
    return gdbx.currencySymbol + ' ';
  }

  public loading: boolean = false;

  public loadingText: string = 'Loading Data.';

  public testSet: any [] = [];

  public testSet2: any [] = [];

  public stockList: any [] = [];

  public tableItems: any = [];

  public closingStockItems: any = [];

  public expandedRows: any = [];


  public selectedFilter: any = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  };

  public selectedFilter2: any = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  };

  public filterIds: Array<
      | 'date'
      | 'dateAsOf'
      | 'dateRange'
      | 'stockItems'
      | 'agents'
      | 'customers'
      | 'suppliers'
      > = ['suppliers', 'agents', 'dateAsOf'];

  @Watch('date')
  public onDateChanged() {
    this.loading = true;
    setTimeout(() => {
      this.newTableItems();
      this.closingStockGroups();
    }, 100);
  }

  public newTableItems() {
    this.loading = true;

    if (this.loading) {
      const nestedArray = nestedGroupBy(this.tableItems.filter((a) => {
        if (moment(this.date[0]).format('YYMMDD') === (moment(this.date[1]).format('YYMMDD'))) {
          return moment(a.docDate).format('YYMMDD') === moment(this.selectedDateRange[1]).format('YYMMDD')
              ;
        } else {
          return a.docDate > this.selectedDateRange[0]
              && a.docDate < this.selectedDateRange[1]
              ;
        }
      }), ['itemCode']);
      const newArray: any [] = [];

      const closingArray = nestedGroupBy(this.closingStockItems, ['itemCode']);

      for (const item of Object.keys(nestedArray)) {
        const eachItem = nestedArray[item][nestedArray[item].length - 1];

        newArray.push({
          ...eachItem,
          description: this.stockList[this.stockList.findIndex((a) => {
            return a.itemCode === eachItem.itemCode;
          })] ? this.stockList[this.stockList.findIndex((a) => {
            return a.itemCode === eachItem.itemCode;
          })].itemDesc : '',
          budgetAmount: parseFloat(eachItem.budgetAmount),
          cost: nestedArray[item].reduce((a, b) =>
              a + b.cost, 0,
          )  ,
          utdCost: closingArray[eachItem.itemCode]
              ? closingArray[eachItem.itemCode][closingArray[eachItem.itemCode].length - 1].utdCost
              : (0).toFixed(2),
          availableBudget: closingArray[eachItem.itemCode]
                  ? parseFloat(eachItem.budgetAmount)
                  -
                  parseFloat(
                  closingArray[eachItem.itemCode][closingArray[eachItem.itemCode].length - 1].utdCost)
                  : (0).toFixed(2)
              ,
          status: closingArray[eachItem.itemCode]
              ? this.status(
                  parseFloat(eachItem.budgetAmount)
                  -
                  parseFloat(
                      closingArray[eachItem.itemCode][closingArray[eachItem.itemCode].length - 1].utdCost),
              ) : 'None',
        });
      }

      this.loading = false;
      this.testSet = newArray;
    }
  }

  public closingStockGroups() {
    const nestedInventory = nestedGroupBy(this.closingStockItems.filter((a) => {
      return a.postDate < this.date[1];
    }), ['itemCode']);

    const modifiedArray: any [] = [];

    for (const item of Object.keys(nestedInventory)) {
      const eachItem = nestedInventory[item][nestedInventory[item].length - 1];

      modifiedArray.push({
        ...eachItem,
        utdCost: eachItem.utdCost,
        utdCostNumeral: eachItem.utdCost,
        description: this.stockList[this.stockList.findIndex((a) => {
          return a.itemCode === eachItem.itemCode;
        })] ? this.stockList[this.stockList.findIndex((a) => {
          return a.itemCode === eachItem.itemCode;
        })].itemDesc : '',
      });
    }


    const nestedArray = nestedGroupBy(modifiedArray, ['stockGroup']);
    const newArray: any [] = [];

    for (const item of Object.keys(nestedArray)) {

      newArray.push({
        stockGroup: item,
        groupQty:
            nestedArray[item].reduce((a, b) =>
                a + b.utdQty, 0,
            ),
        groupAmt: nestedArray[item].reduce((a, b) =>
            a + b.utdCostNumeral, 0,
        ),
        expanded: nestedArray[item].filter((a) => {
          return a.utdQty && a.utdCost;
        }).map((items) => {
          return {
            ...items,
            utdQty: this.thousandFormatter(items.utdQty),
          };
        }),
      });
    }

    this.testSet2 = newArray;
  }

  public expandAll() {
    this.expandedRows = this.testSet2;
  }

  public collapseAll() {
    this.expandedRows = null;
  }

  public exportFileName2(x) {
    const formattedDate = moment(this.selectedDateRange[1]).format('DD MMM YYYY');
    return x + ' ' + 'As Of ' + formattedDate;
  }

  public thousandFormatter(x) {
    return Math.ceil(x).toLocaleString('en-US');
  }

  public status(x) {
    if (x < 0) {
      return 'No Available Budget To Order';
    }
    if (x > 0) {
      return 'Available Budget To Order';

    }
    if (x === 0) {
      return 'None';
    }
  }

  public exportCSV() {
    if (this.$refs.dt as any) {
      (this.$refs.dt as any).exportCSV();
    }
  }

  public exportCSV2() {
    if (this.$refs.dt2Expand as any) {
      (this.$refs.dt2Expand as any).exportCSV();
    }
  }

  public async expensiveCalc() {
    const globalStocks = FilteredDatabase.ref('globalStocks');
    this.stockList = await ( await globalStocks.get()).getCards();

    const closingStocks = FilteredDatabase.ref('closingStockBalance');
    this.closingStockItems = await (await closingStocks.get()).getCards();

    const packagingTable = FilteredDatabase.ref('diyStockPackingBudget');
    this.tableItems = await (await packagingTable.get()).getCards();

    this.newTableItems();
    this.closingStockGroups();
  }

  public async mounted() {
    this.loading = true;

    await this.expensiveCalc();
  }

}
