
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import moment from 'moment';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '../FilterWidget.vue';
import { PermissionsGroup } from '@/store/models.def';
import { TableItemFormatter } from '../../elements/charts/helpers/tableItemFormatter';
import settingx from '@/store/modules/settingx';
import IncludeCreditNoteToggle from '@/components/IncludeCreditNoteToggle.vue';
import IncludeDebitNoteToggle from '@/components/IncludeDebitNoteToggle.vue';

@Component({
  components: {
    TableFilterable,
    IncludeCreditNoteToggle,
    IncludeDebitNoteToggle,
  },
})
export default class MonthTopAgentTable extends FilterWidget {
  public filterIds: Array<
      | 'date'
      | 'dateAsOf'
      | 'dateRange'
      | 'stockItems'
      | 'agents'
      | 'customers'
      | 'suppliers'
  > = [];

  public tableItems: any[] = [];
  public tableFields: any[] = [];

  public get permissionIds(): PermissionsGroup[] {
    return ['sales'];
  }

  public get exportFileName() {
    return 'Top Agent' + '_' + this.monthName + ' ' + this.yearName;
  }

  public get currentMonth() {
    return moment(this.selectedAsOfDate).month();
  }

  public get currentYear() {
    return moment(this.selectedAsOfDate).year();
  }

  public get monthName() {
    return moment().month(this.currentMonth).format('MMM').toUpperCase();
  }

  public get yearName() {
    return moment().year(this.currentYear).format('YY').toUpperCase();
  }

  public get includeCreditNote() {
    return settingx.now.includeCreditNote;
  }

  public get includeDebitNote() {
    return settingx.now.includeDebitNote;
  }

  public get expensiveHook() {
    const {
      selectedDateRange,
      selectedAsOfDate,
      selectedAgents,
      selectedStockItems,
      includeCreditNote,
      includeDebitNote,
    } = this;
    return JSON.stringify([
      selectedDateRange,
      selectedAsOfDate,
      selectedAgents,
      selectedStockItems,
      includeCreditNote,
      includeDebitNote,
    ]);
  }

  public async expensiveCalc() {
    const tradeRef = FilteredDatabase.ref('sales')
        .agents(this.selectedAgents)
        .dateRange(this.selectedDateRange)
        .includes('docType', ['IV', 'CS']);

    const CNRef = FilteredDatabase.ref('creditNotes')
        .agents(this.selectedAgents)
        .dateRange(this.selectedDateRange);

    const DNRef = FilteredDatabase.ref('sales')
        .agents(this.selectedAgents)
        .dateRange(this.selectedDateRange)
        .includes('docType', ['DN']);

    const AllRef = FilteredDatabase.ref('sales')
        .agents(this.selectedAgents)
        .dateRange(this.selectedDateRange)
        .includes('docType', ['IV', 'DN', 'CN']);

    let pa = 0.25;
    let pb = 0;

    const tradeDD = await this._loadDimensionByFilters(
        tradeRef,
        'agents',
        pa,
        pb,
        'amount',
    );

    // tradeDD = FilterWidget.limitSlices(tradeDD, 6);

    pb += pa;
    pa = 0.25;

    const CNDD = await this._loadDimensionByFilters(
        CNRef,
        'agents',
        pa,
        pb,
        'amount',
    );

    // CNDD = FilterWidget.limitSlices(CNDD, 6);

    pb += pa;
    pa = 0.25;

    const DNDD = await this._loadDimensionByFilters(
        DNRef,
        'agents',
        pa,
        pb,
        'amount',
    );

    // DNDD = FilterWidget.limitSlices(DNDD, 6);

    pb += pa;
    pa = 0.25;

    const AllDD = await this._loadDimensionByFilters(
        AllRef,
        'agents',
        pa,
        pb,
        'amount',
    );

    // AllDD = FilterWidget.limitSlices(AllDD, 6);

    // test function

    const totalCreditAmount = CNDD.map((cndd) => {
      return cndd.sum;
    }).reduce((a, b) => a + b, 0);

    const totalDebitAmount = DNDD.map((dndd) => {
      return dndd.sum;
    }).reduce((a, b) => a + b, 0);

    const totalSalesAmount = tradeDD
            .map((dd) => {
              return dd.sum;
            })
            .reduce((a, b) => a + b, 0)
        - (this.includeCreditNote ? totalCreditAmount : 0)
        + (this.includeDebitNote ? totalDebitAmount : 0)
    ;

    const finalArray = tradeDD.map((dd) => {
      const CNindex = CNDD.findIndex((a) => {
        return a.text === dd.text;
      });
      const DNindex = DNDD.findIndex((a) => {
        return a.text === dd.text;
      });
      return {
        agent: dd.text,
        sales: dd.sum
            - ( this.includeCreditNote ? CNDD[CNindex].sum : 0 )
            + ( this.includeDebitNote ? DNDD[DNindex].sum : 0 ),
        percentage: 0,
      };
    });

    let result: Array<{
      agent: string;
      sales: number;
      percentage: number;
    }> = [];


    for (let i = 0; i < 6; i++) {
      if (i < 5) {
        result.push(
            finalArray.sort((a, b) => {
              return b.sales - a.sales;
            })[i],
        );
      } else {
        result.push(
            {
              agent: 'REMAINING AGENTS',
              sales: finalArray.filter((a, index) => {
                if (index > 4) {
                  return a;
                }
              }).reduce((a, b) => {
                return a + b.sales;
              }, 0),
              percentage: 0,
            },
        );
      }
    }

    result = result.map((a) => {
      return {
        ...a,
        percentage: (totalSalesAmount ? (a.sales / totalSalesAmount) * 100 : 0),
      };
    });

    this.tableFields = [
      { key: 'agent', sortable: false },
      {
        key: 'sales',
        sortable: false,
        formatter: TableItemFormatter.currency,
      },
      {
        key: 'percentage',
        sortable: false,
        formatter: TableItemFormatter.percentage,
      },
    ];

    this.tableItems = result;

    this.saveHistory('tableItems', 'tableFields');
  }
}
