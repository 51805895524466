
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TreeMapFilterable from '@/components/reports-v2/components/filterables/TreeMapFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import {
  CustomerSupplierDbCard,
  DimensionData,
  PermissionsGroup,
  ValueText,
} from '@/store/models.def';
import * as echarts from 'echarts';
import IncludeCreditNoteToggle from '@/components/IncludeCreditNoteToggle.vue';
import IncludeDebitNoteToggle from '@/components/IncludeDebitNoteToggle.vue';
import settingx from '@/store/modules/settingx';

@Component({
  components: {
    TreeMapFilterable,
    SelectFilter,
    IncludeCreditNoteToggle,
    IncludeDebitNoteToggle,
  },
})
export default class SalesAnalysisTreeMap extends FilterWidget {
  public get permissionIds(): PermissionsGroup[] {
    return ['sales', 'customers'];
  }

  public filterIds: Array<
      | 'date'
      | 'dateAsOf'
      | 'dateRange'
      | 'stockItems'
      | 'agents'
      | 'customers'
      | 'suppliers'
  > = [];

  public result: any = [];
  public series: echarts.ECharts[] | any = [];

  public selectedGrouping: any = null;

  public get groupingList(): Array<ValueText<string>> {
    const list = [
      { text: 'Stock Item', value: 'stockItems' },
      { text: 'Stock Group', value: 'stockGroup' },
      { text: 'Sales Agent', value: 'salesAgent' },
      { text: 'Customer', value: 'customer' },
      { text: 'Company Categories', value: 'companyCategories' },
      { text: 'Area', value: 'area' },
    ];

    return list;
  }

  public get dateFormatted() {
    return this.selectedDateRange;
  }

  public get includeCreditNote() {
    return settingx.now.includeCreditNote;
  }

  public get includeDebitNote() {
    return settingx.now.includeDebitNote;
  }

  public get expensiveHook() {
    const {
      selectedDateRange,
      selectedAsOfDate,
      selectedAgents,
      selectedCustomers,
      selectedGrouping,
      includeCreditNote,
      includeDebitNote,
    } = this;
    return JSON.stringify([
      selectedDateRange,
      selectedAsOfDate,
      selectedAgents,
      selectedCustomers,
      selectedGrouping,
      includeCreditNote,
      includeDebitNote,
    ]);
  }

  public async expensiveCalc() {
    const salesRef = FilteredDatabase.ref('sales')
        .dateRange(this.selectedDateRange)
        .customers(this.selectedCustomers);

    const customerList = [...(await (await salesRef.get()).getSet('code'))];
    const stockList = [...(await (await salesRef.get()).getSet('itemCode'))];

    const salesCloneRef = FilteredDatabase.ref('sales')
        .dateRange(this.selectedDateRange)
        .stockItems(stockList)
        .customers(customerList);

    const cnRef = FilteredDatabase.ref('creditNotes')
        .dateRange(this.selectedDateRange)
        .stockItems(stockList)
        .customers(customerList);

    const tradeIvDnRef = FilteredDatabase.ref('sales')
        .dateRange(this.selectedDateRange)
        // .stockItems(stockList)
        .customers(customerList)
        .includes('docType', ['IV']);

    const tradeDNRef = FilteredDatabase.ref('sales')
        .dateRange(this.selectedDateRange)
        .stockItems(stockList)
        .customers(customerList)
        .includes('docType', ['DN']);

    const tradeCNRef = FilteredDatabase.ref('sales')
        .dateRange(this.selectedDateRange)
        .stockItems(stockList)
        .customers(customerList)
        .includes('docType', ['CN']);

    const globalRef =
        FilteredDatabase.ref('globalCustomers').customers(customerList);

    const globalCards: CustomerSupplierDbCard[] = await (
        await globalRef.get()
    ).getCards();

    const customerAreaList = {};

    for (const global of globalCards) {
      if (!customerAreaList[global.areaDesc]) {
        customerAreaList[global.areaDesc] = [];
      }
      customerAreaList[global.areaDesc].push(global.code);
    }

    let pa = 0.33;
    let pb = 0;

    let tradeDD: DimensionData[] = [];
    let tradeDNDD: DimensionData[] = [];
    let tradeCNDD: DimensionData[] = [];

    const customerSalesDD = await this._loadDimensionByFilters(
        tradeIvDnRef,
        'customers',
        pa,
        pb,
        'amount',
    );


    const customerDNDD = await this._loadDimensionByFilters(
        tradeDNRef,
        'customers',
        pa,
        pb,
        'amount',
    );

    pb += pa;
    pa = 0.33;

    const customerCNDD = await this._loadDimensionByFilters(
        tradeCNRef,
        'customers',
        pa,
        pb,
        'amount',
    );

    pb += pa;
    pa = 0.33;

    switch (this.selectedGrouping) {
      case 'companyCategories':
        tradeDD = await this._loadDimensionByFilters(
            tradeIvDnRef,
            'customerCategory',
            pa,
            pb,
            (reff, paa, pbb) =>
                this._loadDimensionByFilters(reff, 'customers', paa, pbb, 'amount'),
        );
        tradeDNDD = await this._loadDimensionByFilters(
            tradeDNRef,
            'customerCategory',
            pa,
            pb,
            (reff, paa, pbb) =>
                this._loadDimensionByFilters(reff, 'customers', paa, pbb, 'amount'),
        );
        tradeCNDD = await this._loadDimensionByFilters(
            tradeCNRef,
            'customerCategory',
            pa,
            pb,
            (reff, paa, pbb) =>
                this._loadDimensionByFilters(reff, 'customers', paa, pbb, 'amount'),
        );
        break;
      case 'salesAgent':
        tradeDD = await this._loadDimensionByFilters(
            tradeIvDnRef,
            'agents',
            pa,
            pb,
            'amount',
        );
        tradeDNDD = await this._loadDimensionByFilters(
            tradeDNRef,
            'agents',
            pa,
            pb,
            'amount',
        );
        tradeCNDD = await this._loadDimensionByFilters(
            tradeCNRef,
            'agents',
            pa,
            pb,
            'amount',
        );
        break;
      case 'customer':
        tradeDD = customerSalesDD;
        tradeDNDD = customerDNDD;
        tradeCNDD = customerCNDD;
        break;
      case 'stockGroup':
        tradeDD = await this._loadDimensionByFilters(
            salesCloneRef,
            'stockGroup',
            pa,
            pb,
            (reff, paa, pbb) =>
                this._loadDimensionByFilters(
                    reff,
                    'stockItems',
                    paa,
                    pbb,
                    'amount',
                ),
        );
        tradeCNDD = await this._loadDimensionByFilters(
            cnRef,
            'stockGroup',
            pa,
            pb,
            (reff, paa, pbb) =>
                this._loadDimensionByFilters(
                    reff,
                    'stockItems',
                    paa,
                    pbb,
                    'amount',
                ),
        );
        break;
      case 'stockItems':
        tradeDD = await this._loadDimensionByFilters(
            salesCloneRef,
            'stockItems',
            pa,
            pb,
            'amount',
        );
        tradeCNDD = await this._loadDimensionByFilters(
            cnRef,
            'stockItems',
            pa,
            pb,
            'amount',
        );
      default:
        tradeDD = await this._loadDimensionByFilters(
            salesCloneRef,
            'stockItems',
            pa,
            pb,
            'amount',
        );
        tradeCNDD = await this._loadDimensionByFilters(
            cnRef,
            'stockItems',
            pa,
            pb,
            'amount',
        );
    }

    this.generateLoadingText(1);

    this.result = [];

    if (this.selectedGrouping === 'area') {
      const areaList = Object.keys(customerAreaList);
      const codeList: string[][] = Object.values(customerAreaList);

      areaList.forEach((area, index) => {
        const codes: string[] = codeList[index];
        const object = {
          name: area,
          value: 0,
          children: [] as Array<{
            name: string;
            value: number;
            color: string[];
          }>,
        };

        codes.forEach((code) => {
          const ivdn = customerSalesDD.find((dd) => dd.value[0] === code);
          if (ivdn) {
            object.children.push({
              name: ivdn.text,
              value: ivdn.sum,
              color: [
                '#8975ea',
                '#acbeff',
                '#accdff',
                '#acdaff',
                '#cdc8f9',
                '#c7b5e2',
              ],
            });
          }
        });
        object.value = object.children
            .map((item) => item.value)
            .reduce((a, b) => a + b, 0);
        this.result.push(object);
      });
    } else {
      tradeDD.forEach((trade, index) => {
        const subDD = trade.subDimension;
        const dnSubDD = tradeDNDD[index] ? tradeDNDD[index].subDimension : null;
        const cnSubDD = tradeCNDD[index].subDimension;
        this.result.push({
          name: trade.text,
          value:
              trade.sum
              - (this.includeCreditNote ? tradeCNDD[index].sum : 0)
              + (this.includeDebitNote && tradeDNDD[index] ? tradeDNDD[index].sum : 0),
          color: [
            '#8975ea',
            '#acbeff',
            '#accdff',
            '#acdaff',
            '#cdc8f9',
            '#c7b5e2',
          ],
          colorSaturation: [0.9, 0.4],
          children: subDD
              ? subDD.map((subdd, subIndex) => ({
                name: subdd.text,
                value:
                    subdd.sum -
                    (this.includeCreditNote && cnSubDD
                        ? cnSubDD[subIndex].sum
                        : 0) +
                    (this.includeDebitNote && dnSubDD
                        ? dnSubDD[subIndex].sum : 0),
                color: [
                  '#8975ea',
                  '#acbeff',
                  '#accdff',
                  '#acdaff',
                  '#cdc8f9',
                  '#c7b5e2',
                ],
                colorSaturation: [0.9, 0.4],
              }))
              : [],
        });
      });
    }

    this.result = this.result.filter((item) => item.value > 0);

    this.series = [];

    const stock = {
      name: 'Sales Analysis',
      type: 'treemap',
      roam: 'move',
      width: '100%',
      // drillDownIcon: '👆🏻',
      drillDownIcon: '',
      // leafDepth: 1,
      label: {
        color: '#000',
      },
      itemStyle: {
        gapWidth: 1,
        borderWidth: 1,
      },
      levels: [
        {
          itemStyle: {
            // borderColor: '#555',
            borderWidth: 2,
            gapWidth: 2,
          },
          upperLabel: {
            show: false,
            fontWeight: 'bold',
            color: '#000',
          },
          emphasis: {
            itemStyle: {
              borderWidth: 0,
              gapWidth: 2,
            },
            upperLabel: {
              show: true,
              fontWeight: 'bold',
              color: '#000',
            },
          },
        },
        {
          colorSaturation: [0.3, 0.6],
          itemStyle: {
            borderColorSaturation: 0.7,
            gapWidth: 2,
            borderWidth: 2,
          },
          upperLabel: {
            show: true,
            color: '#000',
            fontWeight: 'bold',
          },
          emphasis: {
            itemStyle: {
              borderWidth: 0,
              gapWidth: 1,
            },
            upperLabel: {
              show: true,
              color: '#000',
              fontWeight: 'bold',
            },
          },
        },
        {
          itemStyle: {
            // borderColor: '#555',
            gapWidth: 1,
            borderWidth: 1,
          },
          upperLabel: {
            show: true,
            color: '#000',
            fontWeight: 'bold',
          },
          emphasis: {
            itemStyle: {
              borderWidth: 0,
              gapWidth: 1,
            },
            upperLabel: {
              show: true,
              color: '#000',
              fontWeight: 'bold',
            },
          },
        },
      ],
      data: this.result,
    };

    this.series = [stock];

    this.saveHistory('result', 'series');
  }

  public onHovering(value: boolean, filterId: string) {
    this.$emit('hovering', value, filterId);
  }
  public onEditing(value: boolean, filterId: string) {
    this.$emit('editing', value, filterId);
  }
}
