
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import { PermissionsGroup, ValueText } from '@/store/models.def';
import { TableItemFormatter } from '@/components/reports-v2/components/elements/charts/helpers/tableItemFormatter';
import CustomerListFilter from '@/components/reports-v2/components/filters/CustomerListFilter.vue';
import IncludeCreditNoteToggle from '@/components/IncludeCreditNoteToggle.vue';
import IncludeDebitNoteToggle from '@/components/IncludeDebitNoteToggle.vue';
import settingx from '@/store/modules/settingx';
import MultiSelect from 'primevue/multiselect';
import gdbx from '@/store/modules/gdbx';
import moment from 'moment';

@Component({
  components: {
    TableFilterable,
    IncludeCreditNoteToggle,
    IncludeDebitNoteToggle,
    CustomerListFilter,
    MultiSelect,
  },
})
export default class StockItemSalesByCustomerTable extends FilterWidget {
  public get permissionIds(): PermissionsGroup[] {
    return ['stocks', 'sales'];
  }

  public filterIds: Array<
      | 'date'
      | 'dateAsOf'
      | 'dateRange'
      | 'stockItems'
      | 'agents'
      | 'customers'
      | 'suppliers'
  > = ['dateRange', 'stockItems', 'customers'];

  public tableItems: any[] = [];
  public tableFields: any[] = [];
  public selectedCustomersList: string[] = [];

  @Watch('options', { immediate: true })
  public onOptionsChanged(newVal: ValueText[]) {
    if (this.selectedCustomersList.length === 0) {
      this.selectedCustomersList = newVal.map((item) => item.value);
    }
  }

  public get options() {
    return gdbx.allCustomers;
  }

  public get exportFileName() {
    const formattedDateRange = [
      moment(this.selectedDateRange[0]).format('DD MMM YY'),
      moment(this.selectedDateRange[1]).format('DD MMM YY'),
    ];
    return (
        'Stock Item Sales By Customer' +
        '_' +
        formattedDateRange[0] +
        ' to ' +
        formattedDateRange[1]
    );
  }

  public get dateFormatted() {
    return this.selectedDateRange;
  }

  public get includeCreditNote() {
    return settingx.now.includeCreditNote;
  }

  public get includeDebitNote() {
    return settingx.now.includeDebitNote;
  }

  public get expensiveHook() {
    const {
      selectedCustomersList,
      includeCreditNote,
      includeDebitNote,
      selectedDateRange,
      selectedCustomers,
      selectedStockItems,
    } = this;
    return JSON.stringify([
      selectedCustomersList,
      includeCreditNote,
      includeDebitNote,
      selectedDateRange,
      selectedCustomers,
      selectedStockItems,
    ]);
  }

  public async expensiveCalc() {
    const ref = FilteredDatabase.ref('sales')
        .customers(this.selectedCustomersList)
        .stockItems(this.selectedStockItems)
        .dateRange(this.selectedDateRange);

    let pa = 0.16;
    let pb = 0;

    const itemList = [...(await (await ref.get()).getSet('itemCode'))];

    const customerList = [...(await (await ref.get()).getSet('code'))];

    const salesRef = FilteredDatabase.ref('sales')
        .dateRange(this.selectedDateRange)
        .customers(customerList)
        .stockItems(itemList);

    const creditNoteRef = FilteredDatabase.ref('creditNotes')
        .dateRange(this.selectedDateRange)
        .customers(customerList)
        .stockItems(itemList);

    pb += pa;
    pa = 0.16;

    const salesAmountDD = await this._loadDimensionByFilters(
        salesRef,
        'stockItems',
        pa,
        pb,
        'amount',
    );

    pb += pa;
    pa = 0.16;

    const salesQuantityDD = await this._loadDimension(
        salesRef,
        salesAmountDD,
        pa,
        pb,
        'quantity',
    );

    pb += pa;
    pa = 0.16;

    const creditNotesAmountDD = await this._loadDimensionByFilters(
        creditNoteRef,
        'stockItems',
        pa,
        pb,
        'amount',
    );

    pb += pa;
    pa = 0.16;

    const profitAmountDD = await this._loadDimensionByFilters(
        salesRef,
        'stockItems',
        pa,
        pb,
        'profit',
    );

    pb += pa;
    pa = 0.16;

    const creditNotesQuantityDD = await this._loadDimensionByFilters(
        creditNoteRef,
        'stockItems',
        pa,
        pb,
        'quantity',
    );

    this.generateLoadingText(1);

    this.tableFields = [
      { key: 'item_code', sortable: true },
      { key: 'item_description', sortable: true },
      { key: 'amount', sortable: true, formatter: TableItemFormatter.currency },
      { key: 'profit', sortable: true, formatter: TableItemFormatter.currency },
      {
        key: 'profit_margin',
        sortable: true,
        formatter: TableItemFormatter.percentage,
      },
      {
        key: 'quantity',
        sortable: true,
        formatter: TableItemFormatter.basic,
      },
    ];

    let result = salesAmountDD.map((dd, index) => {
      const object = {
        item_code: dd.value[0],
        item_description: dd.text,
        amount: this.includeCreditNote
            ? dd.sum - creditNotesAmountDD[index].sum
            : dd.sum,
        profit: profitAmountDD[index].sum,
        profit_margin:
            dd.sum !== 0 ? (profitAmountDD[index].sum / dd.sum) * 100 : 0,
        quantity: this.includeCreditNote
            ? salesQuantityDD[index].sum - creditNotesQuantityDD[index].sum
            : salesQuantityDD[index].sum,
      };

      return object;
    });

    // result = result.filter((item) => item.amount !== 0 && item.quantity !== 0);

    result = result.filter((item) => item.quantity !== 0);

    this.tableItems = result;

    this.saveHistory('tableItems', 'tableFields');
  }

  public onHovering(value: boolean, filterId: string) {
    this.$emit('hovering', value, filterId);
  }
  public onEditing(value: boolean, filterId: string) {
    this.$emit('editing', value, filterId);
  }
}
