import fb from '@/store/gobi-firestore';

const db = fb.db;

const subToRenewal = (onUpdate) => {
  try {
    // Watch the specific document
    return db.collection('supportRenewal')
        .doc('renewalStatusInfo')
        .onSnapshot((doc) => {
          if (doc.exists) {
            onUpdate(doc.data());
          } else {
            console.log('No document exists');
            onUpdate(null);
          }
        }, (error) => {
          console.error('Error in renewal subscription:', error);
        });
  } catch (err) {
    console.error('Error setting up subscription:', err);
    return () => {};
  }
};

const updateRenewal = async (statuses) => {
  try {
    const dataSet = {};
    const keys = Object.keys(statuses);

    keys.forEach((key) => {
      if (statuses[key].length) {
        dataSet[key] = statuses[key].map((item) => {
          const { category, expDate, stage } = item;
          if (category === undefined || expDate === undefined || stage === undefined) {
            console.warn(`Warning: Undefined value found in item for key ${key}:`, item);
            return null; // or you can set default values here
          }
          return { category, expDate, stage };
        }).filter((item) => item !== null);
      }
    });

    const res = await db.collection('supportRenewal')
        .doc('renewalStatusInfo')
        .set(dataSet, { merge: true });

    return true;
  } catch (err) {
    console.error('Error updating renewal:', err);
    return false;
  }
};

export { subToRenewal, updateRenewal };
