
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import settingx from '@/store/modules/settingx';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import SecondaryLineBarChartFilterable from '@/components/reports-v2/components/filterables/SecondaryLineBarChartFilterable.vue';
import IncludeCreditNoteToggle from '@/components/IncludeCreditNoteToggle.vue';
import IncludeDebitNoteToggle from '@/components/IncludeDebitNoteToggle.vue';
import {TableItemFormatter} from '@/components/reports-v2/components/elements/charts/helpers/tableItemFormatter';
import echarts from 'echarts';
import gdbx from '@/store/modules/gdbx';
import {DataViewFormatter} from '@/components/reports-v2/components/elements/charts/helpers/dataViewFormatter';
import moment from 'moment/moment';
import {nestedGroupBy} from '@/util/nestedGroupBy';

@Component({
  components: {
    TableFilterable,
    IncludeCreditNoteToggle,
    IncludeDebitNoteToggle,
    SecondaryLineBarChartFilterable,
  },
})

export default class PalmKingDashboard extends FilterWidget {

  public filterIds: Array<
      | 'date'
      | 'dateAsOf'
      | 'dateRange'
      | 'stockItems'
      | 'agents'
      | 'customers'
      | 'suppliers'
      | 'defaultAgent'
  > = ['stockItems', 'dateRange', 'defaultAgent'];

  public tableItems: any[] = [];
  public tableFields: any[] = [];

  public xAxisData: any [] = [];
  public series: echarts.ECharts[] | any[] = [];
  public seriesData: any = {
    localData: null,
    overseasData: null,
    totalData: null,
  };

  public companyTableItems: any[] = [];
  public companyTableFields: any[] = [];

  public companyTableItems2: any[] = [];
  public companyTableFields2: any[] = [];

  public get exportFileName() {
    return 'Monthly Sales Table as of ' + this.monthName + ' ' + this.yearName;
  }

  public get exportFileName2() {
    return 'Monthly Sales Chart as of ' + this.monthName + ' ' + this.yearName;
  }

  public get exportFileName3() {
    return 'Monthly Sales by Company as of ' + this.monthName + ' ' + this.yearName;
  }

  public get currencySymbol() {
    return gdbx.currencySymbol;
  }

  public get includeCreditNote() {
    return settingx.now.includeCreditNote;
  }

  public get includeDebitNote() {
    return settingx.now.includeDebitNote;
  }

  public get currentMonth() {
    return moment(this.selectedAsOfDate[1]).month();
  }

  public get currentYear() {
    return moment(this.selectedAsOfDate[1]).year();
  }

  public get yearToDate(): [number, number] {
      return [
          moment(this.selectedDateRange[1]).startOf('year').valueOf(),
          moment(this.selectedDateRange[1]).valueOf(),
      ];
  }

  public get monthName() {
    return moment().month(this.currentMonth).format('MMM').toUpperCase();
  }

  public get yearName() {
    return moment().year(this.currentYear).format('YY').toUpperCase();
  }

  public get dataViewFormatter() {
    return DataViewFormatter.salesTracker;
  }

  public get expensiveHook() {
    const {
      selectedDateRange,
      selectedAsOfDate,
      selectedAgents,
      selectedStockItems,
      includeCreditNote,
      includeDebitNote,
    } = this;
    return JSON.stringify([
      selectedDateRange,
      selectedAsOfDate,
      selectedAgents,
      selectedStockItems,
      includeCreditNote,
      includeDebitNote,
    ]);
  }
  public async expensiveCalc() {
    const salesSummaryRef = FilteredDatabase.ref('diySalesSummary')
        .dateRange(this.selectedDateRange)
        .includes('docType', ['IV', 'CS']);

    const dnSalesSummaryRef = FilteredDatabase.ref('diySalesSummary')
        .dateRange(this.selectedDateRange)
        .includes('docType', ['DN']);

    const cnSalesSummaryRef = FilteredDatabase.ref('diySalesSummary')
        .dateRange(this.selectedDateRange)
        .includes('docType', ['CN']);

    const salesSummary = await salesSummaryRef.get();
    const salesSummaryCards = await salesSummary.getCards();

    let pa = 0.027;
    let pb = 0;

    const newSalesSummaryRef = await this._loadDimensionByPeriod(
        salesSummaryRef,
        'month',
        pa,
        pb,
        'amount',
    );

    pb += pa;
    pa = 0.027;

    const newDNSalesSummaryRef = await this._loadDimensionByPeriod(
        dnSalesSummaryRef,
        'month',
        pa,
        pb,
        'amount',
    );

    pb += pa;
    pa = 0.027;

    const newCNSalesSummaryRef = await this._loadDimensionByPeriod(
        cnSalesSummaryRef,
        'month',
        pa,
        pb,
        'amount',
    );

    pb += pa;
    pa = 0.027;

    const newCostSummaryRef = await this._loadDimensionByPeriod(
        salesSummaryRef,
        'month',
        pa,
        pb,
        'refCost',
    );

    pb += pa;
    pa = 0.027;

    this.tableItems = newSalesSummaryRef.map((newArray) => {
      return {
        month: newArray.text,
        sales: newArray.sum,
      };
    });

    if (this.includeDebitNote) {
      for (let i = 0; i < this.tableItems.length; i++) {
        this.tableItems[i].sales =
            this.tableItems[i].sales + newDNSalesSummaryRef[i].sum;
      }
    }

    if (this.includeCreditNote) {
      for (let i = 0; i < this.tableItems.length; i++) {
        this.tableItems[i].sales =
            this.tableItems[i].sales - newCNSalesSummaryRef[i].sum;
      }
    }

    this.tableItems.forEach((item) => {
      newCostSummaryRef.forEach((month) => {
        if (item.month === month.text) {
          item.cost = month.sum;
        }
      });

      const margin = item.sales - item.cost;

      item.margin = margin;
      item.margin_percentage = (margin / item.sales) * 100;
    });

    this.tableFields = [
      {
        key: 'month',
        sortable: false },
      {
        key: 'sales',
        sortable: false,
        formatter: TableItemFormatter.currency,
      },
      {
        key: 'cost',
        sortable: false,
        formatter: TableItemFormatter.currency,
      },
      {
        key: 'margin',
        sortable: false,
        formatter: TableItemFormatter.currency,
      },
      {
        key: 'margin_percentage',
        sortable: false,
        formatter: TableItemFormatter.percentage,
      },
    ];

    // BarChart Data

    const localSalesSummary = FilteredDatabase.ref('diySalesSummary')
        .dateRange(this.selectedDateRange)
        .includes('docType', ['IV', 'CS'])
        .includes('type', ['LOCAL'])
    ;

    const localDNSalesSummary = FilteredDatabase.ref('diySalesSummary')
        .dateRange(this.selectedDateRange)
        .includes('docType', ['DN'])
        .includes('type', ['LOCAL'])
    ;

    const localCNSalesSummary = FilteredDatabase.ref('diySalesSummary')
        .dateRange(this.selectedDateRange)
        .includes('docType', ['CN'])
        .includes('type', ['LOCAL'])
    ;

    const newLocalSalesSummaryRef = await this._loadDimensionByPeriod(
        localSalesSummary,
        'month',
        pa,
        pb,
        'amount',
    );

    pb += pa;
    pa = 0.027;

    const newLocalDNSalesSummaryRef = await this._loadDimensionByPeriod(
        localDNSalesSummary,
        'month',
        pa,
        pb,
        'amount',
    );

    pb += pa;
    pa = 0.027;

    const newLocalCNSalesSummaryRef = await this._loadDimensionByPeriod(
        localCNSalesSummary,
        'month',
        pa,
        pb,
        'amount',
    );

    pb += pa;
    pa = 0.027;

    const localSales = newLocalSalesSummaryRef.map((newArray) => {
      return newArray.sum;
    });

    if (this.includeDebitNote) {
      for (let i = 0; i < localSales.length; i++) {
        localSales[i] = localSales[i] + newLocalDNSalesSummaryRef[i].sum;
      }
    }

    if (this.includeCreditNote) {
      for (let i = 0; i < localSales.length; i++) {
        localSales[i] = localSales[i] - newLocalCNSalesSummaryRef[i].sum;
      }
    }

    const overseaSalesSummary = FilteredDatabase.ref('diySalesSummary')
        .dateRange(this.selectedDateRange)
        .includes('docType', ['IV', 'CS'])
        .includes('type', ['OVERSEA'])
    ;

    const overseaDNSalesSummary = FilteredDatabase.ref('diySalesSummary')
        .dateRange(this.selectedDateRange)
        .includes('docType', ['DN'])
        .includes('type', ['OVERSEA'])
    ;

    const overseaCNSalesSummary = FilteredDatabase.ref('diySalesSummary')
        .dateRange(this.selectedDateRange)
        .includes('docType', ['CN'])
        .includes('type', ['OVERSEA'])
    ;

    const newOverseaSalesSummaryRef = await this._loadDimensionByPeriod(
        overseaSalesSummary,
        'month',
        pa,
        pb,
        'amount',
    );

    pb += pa;
    pa = 0.027;

    const newOverseasDNSalesSummaryRef = await this._loadDimensionByPeriod(
        overseaDNSalesSummary,
        'month',
        pa,
        pb,
        'amount',
    );

    pb += pa;
    pa = 0.027;

    const newOverseasCNSalesSummaryRef = await this._loadDimensionByPeriod(
        overseaCNSalesSummary,
        'month',
        pa,
        pb,
        'amount',
    );

    pb += pa;
    pa = 0.027;

    const overseasSales = newOverseaSalesSummaryRef.map((newArray) => {
      return newArray.sum;
    });

    if (this.includeDebitNote) {
      for (let i = 0; i < overseasSales.length; i++) {
        overseasSales[i] =
            overseasSales[i] + newOverseasDNSalesSummaryRef[i].sum;
      }
    }

    if (this.includeCreditNote) {
      for (let i = 0; i < overseasSales.length; i++) {
        overseasSales[i] =
            overseasSales[i] - newOverseasCNSalesSummaryRef[i].sum;
      }
    }

    const totalSales: number[] = [];

    for (let i = 0; i < localSales.length; i++) {
      const total = localSales[i] + overseasSales[i];
      totalSales.push(total);
    }

    this.seriesData.localData = localSales;
    this.seriesData.overseasData = overseasSales;
    this.seriesData.totalData = totalSales;

    this.xAxisData = newOverseaSalesSummaryRef.map((month) => {
        return month.text;
    });

    this.series = [
      {
        name: 'Local',
        data: this.seriesData.localData,
        type: 'bar',
        barWidth: '25%',
      },
      {
        name: 'Overseas',
        data: this.seriesData.overseasData,
        type: 'bar',
        barWidth: '25%',
      },
      {
        name: 'Total',
        data: this.seriesData.totalData,
        type: 'bar',
        barWidth: '25%',
      },
    ];

    this.companyTableItems = [];

    newLocalSalesSummaryRef.forEach((newArray) => {
      this.companyTableItems.push({
        month: newArray.text,
        total_local: newArray.sum,
      });
    });

    this.companyTableItems.forEach((item) => {
      newOverseaSalesSummaryRef.forEach((month) => {
        if (item.month === month.text) {
          item.total_overseas = month.sum;
        }
      });
    });

    if (this.includeDebitNote) {
      for (let i = 0; i < this.companyTableItems.length; i++) {
        this.companyTableItems[i].total_local =
            this.companyTableItems[i].total_local + newLocalDNSalesSummaryRef[i].sum;
      }
    }

    if (this.includeCreditNote) {
      for (let i = 0; i < this.companyTableItems.length; i++) {
        this.companyTableItems[i].total_local =
            this.companyTableItems[i].total_local - newLocalCNSalesSummaryRef[i].sum;
      }
    }

    this.companyTableItems.forEach((item) => {
      item.total = item.total_local + item.total_overseas;
    });

    this.companyTableFields = [
      {
        key: 'month',
        sortable: false,
      },
      {
        key: 'total_local',
        sortable: false,
        formatter: TableItemFormatter.currency,
      },
      {
        key: 'total_overseas',
        sortable: false,
        formatter: TableItemFormatter.currency,
      },
      {
        key: 'total',
        sortable: false,
        formatter: TableItemFormatter.currency,
      },
    ];

    this.companyTableFields2 = [
      {
        key: 'month',
        sortable: false,
      },
    ];

    const groupedSalesSummary = nestedGroupBy(salesSummaryCards, ['companyCategory']);
    this.companyTableItems2 = [];

    for (const key of Object.keys(groupedSalesSummary)) {

      const newRef = FilteredDatabase.ref('diySalesSummary')
          .dateRange(this.selectedDateRange)
          .includes('companyCategory', [key])
          .includes('docType', ['IV', 'CS']);

      const newDNRef = FilteredDatabase.ref('diySalesSummary')
          .dateRange(this.selectedDateRange)
          .includes('companyCategory', [key])
          .includes('docType', ['DN']);

      const newCNRef = FilteredDatabase.ref('diySalesSummary')
          .dateRange(this.selectedDateRange)
          .includes('companyCategory', [key])
          .includes('docType', ['CN']);

      const newCompanyGroupedSalesRef = await this._loadDimensionByPeriod(
          newRef,
          'month',
          pa,
          pb,
          'amount',
      );

      pb += pa;
      pa = 0.027;

      const newCompanyGroupedDNSalesRef = await this._loadDimensionByPeriod(
          newDNRef,
          'month',
          pa,
          pb,
          'amount',
      );

      pb += pa;
      pa = 0.027;

      const newCompanyGroupedCNSalesRef = await this._loadDimensionByPeriod(
          newCNRef,
          'month',
          pa,
          pb,
          'amount',
      );

      if (!this.companyTableItems2.length) {
        this.companyTableItems2 = newCompanyGroupedSalesRef.map((group) => {
          return {
            month: group.text,
          };
        });
      }

      for (let i = 0; i < this.companyTableItems2.length; i++) {
        this.companyTableItems2[i][key] = newCompanyGroupedSalesRef[i].sum || 0;

        if (this.includeDebitNote) {
            this.companyTableItems2[i][key] =
                this.companyTableItems2[i][key] + newCompanyGroupedDNSalesRef[i].sum || 0;
          }

        if (this.includeCreditNote) {
            this.companyTableItems2[i][key] =
                this.companyTableItems2[i][key] - newCompanyGroupedCNSalesRef[i].sum || 0;
          }
      }

      this.companyTableFields2.push({
          key,
          sortable: false,
          formatter: TableItemFormatter.currency,
        });

    }

    this.saveHistory(
        'tableItems',
        'tableFields',
        'series',
        'seriesData',
        'xAxisData',
        'companyTableItems',
        'companyTableFields',
        'companyTableItems2',
        'companyTableFields2',
    );
  }
}
