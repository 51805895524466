
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import NewSummaries from './NewSummaries.vue';
import LastSync from '@/components/LastSync.vue';
import BaseReport from '../../BaseReport.vue';
import userx from '@/store/modules/userx';
import router from '@/router';
import TitleBar from '@/components/reports-v2/pages/dashboard/TitleBar.vue';
import StickyFilterContainer from '@/components/reports-v2/components/pages/StickyFilterContainer.vue';
import TodaySalesNumberDisplayer from '@/components/reports-v2/components/codedWidgets/dashboard/TodaySalesNumberDisplayer.vue';
import TodayProfitNumberDisplayer from '@/components/reports-v2/components/codedWidgets/dashboard/TodayProfitNumberDisplayer.vue';
import TodayQuotationNumberDisplayer from '@/components/reports-v2/components/codedWidgets/dashboard/TodayQuotationNumberDisplayer.vue';
import SalesAgentRankingTable from '@/components/reports-v2/components/codedWidgets/dashboard/SalesAgentRankingTable.vue';
import SalesCustomerRankingTable from '@/components/reports-v2/components/codedWidgets/dashboard/SalesCustomerRankingTable.vue';
import DateFilter from '@/components/reports-v2/components/filters/DateFilter.vue';
import DateRangeFilter from '@/components/reports-v2/components/filters/DateRangeFilter.vue';
import InstafreshDashboard from '@/components/reports-v2/components/codedWidgets/dashboard/InstafreshDashboard.vue';
import TongYuenSalesOverview from '@/components/reports-v2/components/codedWidgets/Copy/TongYuenSalesOverview.vue';
import moment from 'moment';
import gdbx from '@/store/modules/gdbx';
import SalesInvoiceCreditNoteLineChart from '@/components/reports-v2/components/codedWidgets/dashboard/SalesInvoiceCreditNoteLineChart.vue';
import QuotationLineChart from '@/components/reports-v2/components/codedWidgets/dashboard/QuotationLineChart.vue';
import CashOnHandDisplayer from '@/components/reports-v2/components/codedWidgets/dashboard/CashOnHandDisplayer.vue';
import CashInBankDisplayer from '@/components/reports-v2/components/codedWidgets/dashboard/CashInBankDisplayer.vue';
import CashFlowDisplayer from '@/components/reports-v2/components/codedWidgets/dashboard/CashFlowDisplayer.vue';
import CashInDisplayer from '@/components/reports-v2/components/codedWidgets/dashboard/CashInDisplayer.vue';
import CashOutDisplayer from '@/components/reports-v2/components/codedWidgets/dashboard/CashOutDisplayer.vue';
import TodayCustomerDueTable from '@/components/reports-v2/components/codedWidgets/dashboard/TodayCustomerDueTable.vue';
import TodaySupplierDueTable from '@/components/reports-v2/components/codedWidgets/dashboard/TodaySupplierDueTable.vue';
import accountx from '@/store/modules/accountx';
import VivalecDashboard from '@/components/reports-v2/components/codedWidgets/dashboard/VivalecDashboard.vue';
import PalmKingDashboard from '@/components/reports-v2/components/codedWidgets/dashboard/PalmKingDashboard.vue';
import TiarcoChemicalM from '@/components/reports-v2/components/codedWidgets/dashboard/TiarcoChemicalM.vue';
import ProjectRadioFilter from '@/components/reports-v2/components/filters/ProjectRadioFilter.vue';
import ProjectFilter from '@/components/reports-v2/components/filters/ProjectSelectFilter.vue';
import NonStopAutoDashboard from '@/components/reports-v2/components/codedWidgets/dashboard/NonStopAutoDashboard.vue';
import BICustomerRenewalDashboard from '../../components/codedWidgets/customisation/BICustomerRenewalDashboard.vue';
import NYCXCustomDashboard from '@/components/reports-v2/components/codedWidgets/dashboard/NYCXCustomDashboard.vue';

@Component({
  components: {
      NYCXCustomDashboard,
    BICustomerRenewalDashboard,
    NonStopAutoDashboard,
    ProjectFilter,
    ProjectRadioFilter,
    TiarcoChemicalM,
    VivalecDashboard,
    PalmKingDashboard,
    TongYuenSalesOverview,
    NewSummaries,
    LastSync,
    TitleBar,
    TodaySalesNumberDisplayer,
    TodayProfitNumberDisplayer,
    TodayQuotationNumberDisplayer,
    InstafreshDashboard,
    StickyFilterContainer,
    DateFilter,
    DateRangeFilter,
    SalesAgentRankingTable,
    SalesCustomerRankingTable,
    SalesInvoiceCreditNoteLineChart,
    QuotationLineChart,
    CashOnHandDisplayer,
    CashInBankDisplayer,
    CashFlowDisplayer,
    CashInDisplayer,
    CashOutDisplayer,
    TodayCustomerDueTable,
    TodaySupplierDueTable,
    // AllFilter,
  },
})
export default class MyFavorite extends BaseReport {
  public id: any = '';
  public selectedDay: number = moment().endOf('day').valueOf();
  public selectedProjects = [];

  public manualDateRange: [number, number] = [
    moment().add(0, 'month').startOf('month').valueOf(),
    moment().valueOf(),
  ];

  public get selectedCurrentMonthRange(): [number, number] {
    return [
      moment(this.selectedDay).subtract(12, 'months').startOf('month').valueOf(),
      moment(this.selectedDay).endOf('month').valueOf(),
    ];
  }

  public get selectedDateRange() {
    return [
      moment(this.selectedDay).startOf('month').valueOf(),
      moment(this.selectedDay).endOf('day').valueOf(),
    ];
  }

  public get selectedAgents(): string[] {
    return gdbx.allAgents.map((item) => item.value);
  }

  public get selectedCustomers(): string[] {
    return gdbx.allCustomers.map((item) => item.value);
  }

  public get selectedAsOfDate() {
    return moment(this.selectedDay).endOf('day').valueOf();
  }

  public mounted() {
    this.id = accountx.currentAccountId;
    this.track();

    if (accountx.currentAccountId === 'HsJ2Zj1jFkQVkxOKmxvk') {
      this.$router.push('/account/' + accountx.currentAccountId + '/dealer');
    }
  }

  public track() {
    this.$ga.set('userId', userx.user!.uid + '_' + userx.user!.email);
    this.$ga.page(router);
  }
}
