
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import moment from 'moment';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '../FilterWidget.vue';
import { PermissionsGroup } from '@/store/models.def';
import { TableItemFormatter } from '../../elements/charts/helpers/tableItemFormatter';
import IncludeCreditNoteToggle from '@/components/IncludeCreditNoteToggle.vue';
import IncludeDebitNoteToggle from '@/components/IncludeDebitNoteToggle.vue';
import settingx from '@/store/modules/settingx';

@Component({
  components: {
    TableFilterable,
    IncludeCreditNoteToggle,
    IncludeDebitNoteToggle,
  },
})
export default class MonthTopCustomerTable extends FilterWidget {
  public filterIds: Array<
      | 'date'
      | 'dateAsOf'
      | 'dateRange'
      | 'stockItems'
      | 'agents'
      | 'customers'
      | 'suppliers'
  > = [];

  public tableItems: any[] = [];
  public tableFields: any[] = [];

  public get permissionIds(): PermissionsGroup[] {
    return ['sales', 'customers'];
  }

  public get exportFileName() {
    return 'Top Customer' + '_' + this.monthName + ' ' + this.yearName;
  }

  public get currentMonth() {
    return moment(this.selectedAsOfDate).month();
  }

  public get currentYear() {
    return moment(this.selectedAsOfDate).year();
  }

  public get monthName() {
    return moment().month(this.currentMonth).format('MMM').toUpperCase();
  }

  public get yearName() {
    return moment().year(this.currentYear).format('YY').toUpperCase();
  }

  public get includeCreditNote() {
    return settingx.now.includeCreditNote;
  }

  public get includeDebitNote() {
    return settingx.now.includeDebitNote;
  }

  public get expensiveHook() {
    const {
      selectedDateRange,
      selectedAsOfDate,
      selectedStockItems,
      selectedCustomers,
      includeCreditNote,
      includeDebitNote,
    } = this;
    return JSON.stringify([
      selectedDateRange,
      selectedAsOfDate,
      selectedStockItems,
      selectedCustomers,
      includeCreditNote,
      includeDebitNote,
    ]);
  }

  public async expensiveCalc() {
    const tradeRef = FilteredDatabase.ref('sales')
        .dateRange(this.selectedDateRange)
        .customers(this.selectedCustomers)
        .includes('docType', ['IV']);

    const customerList = [...(await (await tradeRef.get()).getSet('code'))];

    const tradeRef2 = FilteredDatabase.ref('sales')
        .dateRange(this.selectedDateRange)
        .customers(customerList)
        .includes('docType', ['IV']);

    const CNRef = FilteredDatabase.ref('sales')
        .dateRange(this.selectedDateRange)
        .customers(customerList)
        .includes('docType', ['CN']);

    const DNRef = FilteredDatabase.ref('sales')
        .dateRange(this.selectedDateRange)
        .customers(customerList)
        .includes('docType', ['DN']);

    let pa = 0.33;
    let pb = 0;

    let tradeDD = await this._loadDimensionByFilters(
        tradeRef2,
        'customers',
        pa,
        pb,
        'amount',
    );

    tradeDD = FilterWidget.limitSlices(tradeDD, 6);

    pb += pa;
    pa = 0.33;

    const CNDD = await this._loadDimensionByFilters(
        CNRef,
        'customers',
        pa,
        pb,
        'amount',
    );

    pb += pa;
    pa = 0.33;

    const DNDD = await this._loadDimensionByFilters(
        DNRef,
        'customers',
        pa,
        pb,
        'amount',
    );

    this.tableFields = [
      { key: 'customer', sortable: false },
      {
        key: 'sales',
        sortable: false,
        formatter: TableItemFormatter.currency,
      },
      {
        key: 'percentage',
        sortable: false,
        formatter: TableItemFormatter.percentage,
      },
    ];

    const totalSalesAmount = tradeDD
        .map((dd) => {
          const CNIndex = CNDD.findIndex((cndd) => cndd.value[0] === dd.value[0]);
          const DNIndex = DNDD.findIndex((dndd) => dndd.value[0] === dd.value[0]);
          return dd.sum - (this.includeCreditNote ? CNDD[CNIndex].sum : 0)
              + (this.includeDebitNote ? DNDD[DNIndex].sum : 0);
        })
        .reduce((a, b) => a + b, 0);

    const result: Array<{
      customer: string;
      sales: number;
      percentage: number;
    }> = [];

    for (const dd of tradeDD) {
      const item: any = {
        customer: dd.text,
        sales: 0,
        percentage: 0,
      };

      const CNIndex = CNDD.findIndex((cndd) => cndd.value[0] === dd.value[0]);
      const DNIndex = DNDD.findIndex((dndd) => dndd.value[0] === dd.value[0]);

      item.sales = dd.sum - (this.includeCreditNote ? CNDD[CNIndex].sum : 0)
          + (this.includeDebitNote ? DNDD[DNIndex].sum : 0);

      item.percentage =
          totalSalesAmount !== 0
              ? ((dd.sum - (this.includeCreditNote ? CNDD[CNIndex].sum : 0)
                      + (this.includeDebitNote ? DNDD[DNIndex].sum : 0)) /
                  totalSalesAmount) *
              100
              : 0;

      result.push(item);
    }

    const otherIndex = result.findIndex((value) => value.customer === 'Others');

    if (otherIndex !== -1) {
      result[otherIndex].customer = 'Remaining Customers'.toUpperCase();

      const extractedItem = result.splice(otherIndex, 1);

      result.push(...extractedItem);
    }

    this.tableItems = result;

    this.saveHistory('tableItems', 'tableFields');
  }
}
