
import { Component } from 'vue-property-decorator';
import FilterWidget from '../FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import {PermissionsGroup, ValueText} from '@/store/models.def';
import DateFilter from '../../filters/DateFilter.vue';
import moment from 'moment';
import Button from 'primevue/button';
import ListFilter from '@/components/reports-v2/components/filters/ListFilter.vue';
import GeneralListFilter from '@/components/reports-v2/components/filters/GeneralListFilter.vue';
import {subToRenewal, updateRenewal} from '@/components/supportRenewal/supportRenewal';

interface Expiration {
    category?: string,
    expDate?: number,
    stage?: string,
}

@Component({
    components: {
        GeneralListFilter,
        ListFilter,
        Button,
        FilterWidget,
        DateFilter,
    },
})
export default class BICustomerRenewalDashboard extends FilterWidget {
    public get permissionIds(): PermissionsGroup[] {
        return ['dashboard'];

    }

    public selectedAsOfDate = moment(Date.now()).valueOf();
    public filteredDataArray: any[] = [];
    public dataArray: any[] = [];
    public searchQuery: string = '';
    public isModalVisible: boolean = false;
    public selectedCompany: any = [];
    public selectedStatus: any[] = [];
    public selectedExpiration: Expiration = {};
    public selectedExpirationStage: string = '';
    public statuses: ValueText[] = [
        {
            value: 'Expired',
            text: 'Expired',
        },
        {
            value: 'Expiring',
            text: 'Expiring',
        },
        {
            value: 'Expiring Soon',
            text: 'Expiring Soon',
        },
        {
            value: 'Active',
            text: 'Active',
        },
    ];
    public stages: string[] = [
        'Reminder 1',
        'Reminder 2',
        'Reminder 3',
        'Quotation',
        'Proforma invoice',
        'Invoice',
    ];

    public statusOrder = {
        'Expired': 1,
        'Expiring': 2,
        'Expiring Soon': 3,
        'Active': 4,
    };

    public unsubscribe: (() => void) | null = null;
    public renewalStatus: any = null;

    public filterData() {
        this.filteredDataArray = this.dataArray.filter((a) => {
            return a.companyName.toLowerCase().includes(this.searchQuery.toLowerCase()) &&
                this.selectedStatus.includes(a.status);
        }).sort((a, b) => {
            const statusA = this.statusOrder[a.status];
            const statusB = this.statusOrder[b.status];
            return statusA - statusB;
        });
    }

    public copyContent(): void {
        const container = document.querySelector('.container-2.right-container');
        if (container) {

            // Create a temporary textarea element to copy the content
            const textArea = document.createElement('textarea');
            textArea.value = container.textContent || '';
            document.body.appendChild(textArea);
            textArea.select();

            document.execCommand('copy');

            document.body.removeChild(textArea);
        }
    }

    public copyCompanyName(companyName: string): void {

        const tempInput = document.createElement('input');
        tempInput.value = companyName;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempInput);

    }
    public openOutlook(): void {
        const recipient = `${this.selectedCompany.email}`; // Replace with actual recipient email
        const cc = 'team@golink.com.my';
        const subject = `Status Update: ${this.selectedCompany.stage}`; // Dynamic subject based on the current status
        const body = `Dear Sir/Madam,\n\nKindly find the ${this.selectedCompany.stage} for ${this.selectedCompany.companyName} as attached along this email based on your requirements.
Appreciate you can review it and please inform us if further clarification needed.
Looking forward to hear from you soon.

Thank you and have a nice day.
`;

        const mailtoLink = `mailto:${recipient}?cc=${cc}&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

        window.location.href = mailtoLink;
    }


    public get currentYearRange(): [number, number] {
        return [
            moment('01/01/2016').startOf('year').valueOf(),
            moment(this.selectedAsOfDate).endOf('month').valueOf(),
        ];
    }

    public get endOfMonth(): number {
        return moment(this.selectedAsOfDate).endOf('month').valueOf();
    }

    public showModal(company: any): void {
        this.selectedCompany = company;
        this.isModalVisible = true;
    }
    public toggleExpirationDate(item) {
        this.selectedExpiration = item;
        this.selectedExpirationStage = this.selectedExpiration.stage || 'Reminder 1';
    }

    public updateStage(item, selectedStage) {

        item.stage = selectedStage;
    }

    public saveStageAndStatus() {

        const index = this.filteredDataArray
            .findIndex((array) => {
            return array.companyName === this.selectedCompany.companyName;
        });

        const expiryIndex = this.filteredDataArray[index]
            .details
            .expirationDates
            .findIndex((date) => {
        return date.expDate === this.selectedExpiration.expDate && date.category === this.selectedExpiration.category;
        });

        this.filteredDataArray[index]
            .details.expirationDates[expiryIndex].stage
            = this.selectedExpirationStage;

        const newObj = {};

        this.filteredDataArray.forEach((array) => {
            newObj[array.companyName] = array.details.expirationDates;
        });

        updateRenewal(newObj);
        this.closeModal();
    }

    public moment(date) {
        return moment(date).format('DD/MM/YY');
    }

    public closeModalWithoutStatusChange() {
        this.selectedExpiration = {
            category: '',
            expDate: 0,
            stage: '',
        };
        this.isModalVisible = false;
    }

    public closeModal(): void {
        this.isModalVisible = false;
        document.body.style.overflow = 'auto';
    }

    public expiredOrNot(status) {
        if (status === 'Expired') {
            return 'status status-red';
        }
        if (status === 'Expiring') {
            return 'status status-orange';
        }
        if (status === 'Expiring Soon') {
            return 'status status-yellow';
        }
        if (status === 'Active') {
            return 'status status-green';
        }
    }

    public getStatus(code) {
        const closestExpiry = code.details.expirationDates.length ? code.details.expirationDates.sort((a, b) => {
            return a.expDate - b.expDate;
        })[0].expDate : 0;
        const timeNow = moment(Date.now()).valueOf();

        const fromNow = closestExpiry ? moment(closestExpiry).diff(timeNow, 'days') : closestExpiry;

        if (fromNow < 0) {
            return 'Expired';
        }

        if (fromNow <= 7) {
            return 'Expiring';
        }

        if (fromNow <= 30) {
            return 'Expiring Soon';
        }

        if (fromNow > 30) {
            return 'Active';
        }

        return fromNow;
    }

    public get expensiveHook() {
        const {
            selectedDateRange,
            selectedAsOfDate,
            endOfMonth,
            currentYearRange,
            selectedProjects,
        } = this;
        return JSON.stringify([
            selectedDateRange,
            selectedAsOfDate,
            endOfMonth,
            currentYearRange,
            selectedProjects,
        ]);
    }

    public async expensiveCalc() {
        const initialDataPromise = new Promise((resolve) => {
            this.unsubscribe = subToRenewal((data) => {
                this.renewalStatus = data;
                resolve(data); // Resolve the promise with the initial data
            });
        });

        // Wait for initial data
        const renewalData: any = await initialDataPromise;

        const supportRef = FilteredDatabase.ref('diySupportRenewal')
            .dateAsOf(this.endOfMonth, 'expDate');
        const supportCards = await (await supportRef.get()).getCards();

        const supportRefIv = FilteredDatabase.ref('diySupportRenewal')
            .dateRange([0, this.endOfMonth], 'expDate')
            .includes('docType', ['IV']);
        const supportCardsIv = await (await supportRefIv.get()).getCards();

        const companyCodes = new Set(supportCards.map((card) => card.code));

        const newArray: any[] = [];

        companyCodes.forEach((code) => {
            const firstCompany = supportCards.find((card) => card.code === code) || {};

            const obj: any = {
                masterCompanyName: firstCompany ? firstCompany.masterCompanyName : '',
                companyName: firstCompany ? firstCompany.companyName : '',
                email: firstCompany ? firstCompany.email : '',
                stage: '',
                details: {
                    categories: {} as {
                        [key: string]: {
                            fee: number;
                            modules: Array<{
                                itemDesc: string;
                                qty: number;
                                uom: string;
                                amount: number;
                                itemCode: string;
                                email: string;
                            }>
                        },
                    },
                    IVcategory: {
                        subcategories: {} as {
                            [key: string]: {
                                fee: number;
                                modules: Array<{
                                    itemDesc: string;
                                    qty: number;
                                    uom: string;
                                    amount: number;
                                    itemCode: string;
                                }>
                            },
                        },
                    },
                    expirationDates: [] as Array<{ category: string; expDate?: number, stage?: string }>,
                },

            };

            // Process MD data
            const mdItems = supportCards.filter((card) => card.code === code && card.docType === 'MD');
            mdItems.forEach((item) => {
                const category = item.desc || 'Unknown Category';
                if (!obj.details.categories[category]) {
                    obj.details.categories[category] = { fee: 0, modules: [] };
                }
                obj.details.categories[category].fee += item.amount || 0;
                obj.details.categories[category].modules.push({
                    itemDesc: item.itemDesc || '',
                    qty: item.qty || 0,
                    uom: item.uom || '',
                    amount: item.amount || 0,
                    itemCode: item.itemCode || '',
                });
            });

            // Process IV data
            const ivItems = supportCardsIv.filter((card) => card.code === code);
            ivItems.forEach((ivItem) => {
                const subcategory = ivItem.desc || 'Unknown Category';
                if (!obj.details.IVcategory.subcategories[subcategory]) {
                    obj.details.IVcategory.subcategories[subcategory] = { fee: 0, modules: [] };
                }
                obj.details.IVcategory.subcategories[subcategory].fee += ivItem.amount || 0;
                obj.details.IVcategory.subcategories[subcategory].modules.push({
                    itemDesc: ivItem.itemDesc || '',
                    qty: ivItem.qty || 0,
                    uom: ivItem.uom || '',
                    amount: ivItem.amount || 0,
                    itemCode: ivItem.itemCode || '',
                });

                if (ivItem.expDate) {
                    // Check if an item with the same expDate and category already exists in the expirationDates array
                    const existingItem = renewalData[obj.companyName] ? renewalData[obj.companyName].find(
                        (expiration: { expDate: any; category: any; }) =>
                            expiration.expDate === ivItem.expDate
                            && expiration.category === ivItem.desc,
                    ) : {};

                    // If no such item exists, push the new expiration date object
                   obj.details.expirationDates.push(
                            {
                                category: ivItem.desc || 'Unknown Category',
                                expDate: ivItem.expDate,
                                stage: existingItem && Object.keys(existingItem).length > 0
                                    ? existingItem.stage : 'Reminder 1',
                            },
                        );
                }

            });

            obj.details.expirationDates = obj.details.expirationDates.filter(
                (value, index, self) =>
                    index === self.findIndex((expDate) =>
                        expDate.category === value.category
                        && expDate.expDate === value.expDate,
                    ),
            );

            obj.status = this.getStatus(obj);

            newArray.push(obj);
        });

        this.dataArray = newArray;
        this.filteredDataArray = newArray.sort((a, b) => {
            const statusA = this.statusOrder[a.status];
            const statusB = this.statusOrder[b.status];
            return statusA - statusB;
        });
    }

}
