
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import AmountDisplayerFilterable from '@/components/reports-v2/components/filterables/AmountDisplayerFilterable.vue';
import moment from 'moment';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '../FilterWidget.vue';
import { PermissionsGroup } from '@/store/models.def';
import { roundToFixed } from '@/util/number';
import gdbx from '@/store/modules/gdbx';
import ShowProfitToggle from '@/components/ShowProfitToggle.vue';
import settingx from '@/store/modules/settingx';
import IncludeCreditNoteToggle from '@/components/IncludeCreditNoteToggle.vue';
import IncludeDebitNoteToggle from '@/components/IncludeDebitNoteToggle.vue';

@Component({
  components: {
    AmountDisplayerFilterable,
    ShowProfitToggle,
    IncludeCreditNoteToggle,
    IncludeDebitNoteToggle,
  },
})
export default class MonthSalesProfitNumberDisplayer extends FilterWidget {
  public filterIds: Array<
      | 'date'
      | 'dateAsOf'
      | 'dateRange'
      | 'stockItems'
      | 'agents'
      | 'customers'
      | 'suppliers'
  > = [];

  public profitPercentage = 0;
  public profitAmount = '' || 0;

  public get permissionIds(): PermissionsGroup[] {
    return ['customers', 'sales'];
  }

  public get title() {
    return this.showProfit ? 'Estimate Profit' : 'Estimate Margin';
  }

  public get showProfit() {
    return settingx.now.showProfit;
  }

  public get includeCreditNote() {
    return settingx.now.includeCreditNote;
  }

  public get includeDebitNote() {
    return settingx.now.includeDebitNote;
  }

  public get currentYear() {
    return moment(this.selectedAsOfDate).year();
  }

  public get currentMonthRange(): [number, number] {
    return [
      moment(this.selectedAsOfDate).startOf('month').valueOf(),
      moment(this.selectedAsOfDate).endOf('month').valueOf(),
    ];
  }

  public get currency() {
    return gdbx.currencySymbol;
  }

  public get decimal() {
    return gdbx.numDecimal;
  }

  public get expensiveHook() {
    const {
      selectedDateRange,
      selectedAsOfDate,
      selectedAgents,
      selectedStockItems,
      selectedSuppliers,
      includeCreditNote,
      includeDebitNote,
    } = this;
    return JSON.stringify([
      selectedDateRange,
      selectedAsOfDate,
      selectedAgents,
      selectedStockItems,
      selectedSuppliers,
      includeCreditNote,
      includeDebitNote,
    ]);
  }

  public async expensiveCalc() {
    const salesRef = FilteredDatabase.ref('sales')
        .agents(this.selectedAgents)
        .dateRange(this.currentMonthRange);

    const creditNoteRef = FilteredDatabase.ref('creditNotes')
        .agents(this.selectedAgents)
        .dateRange(this.currentMonthRange);

    const salesAmount =
        (await (await salesRef.get()).getSum('amount')) -
        (this.includeCreditNote
            ? await (await creditNoteRef.get()).getSum('amount')
            : 0);

    const salesProfit =
        (await (await salesRef.get()).getSum('profit')) -
        (this.includeCreditNote
            ? await (await creditNoteRef.get()).getSum('profit')
            : 0);

    this.profitAmount = roundToFixed(salesProfit, this.decimal);

    this.profitPercentage = roundToFixed(
        salesAmount !== 0 ? (salesProfit / salesAmount) * 100 : 0,
        this.decimal,
    );

    this.saveHistory('profitAmount', 'profitPercentage');
  }
}
