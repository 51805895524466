
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import Component from 'vue-class-component';
import moment, {MomentInput} from 'moment';
import {FilteredDatabase} from '@/worker/fd/FilteredDatabase';
import {nestedGroupBy} from '@/util/nestedGroupBy';
import { TableItemFormatter } from '../../elements/charts/helpers/tableItemFormatter';

@Component({
  components: {
    TableFilterable,
  },
})
export default class TiarcoChemicalMOtherIncomeBreakdownTable extends FilterWidget {
  public filterIds: Array<
      | 'date'
      | 'month'
      | 'dateAsOf'
      | 'dateRange'
      | 'stockItems'
      | 'agents'
      | 'customers'
      | 'suppliers'
      | 'project'
  > = ['suppliers', 'agents', 'dateAsOf', 'month', 'project'];

  public tableItems: object[] = [];
  public tableFields: object[] = [];

  public get currentYearRange(): [number, number] {
    return [
      moment(this.selectedAsOfDate).startOf('year').valueOf(),
      moment(this.selectedAsOfDate).endOf('month').valueOf(),
    ];
  }

  public getMonthYearArray(startDate, endDate) {
    const start = moment(startDate);
    const end = moment(endDate);

    const monthYearArray: string[] = [];

    while (start.isBefore(end) || start.isSame(end, 'month')) {
      monthYearArray.push(start.format('MMMYY'));
      start.add(1, 'month');
    }

    return monthYearArray;
  }

  public exportName(range: number[]) {
    return 'Other Income Breakdown' + ' from ' +
        moment(range[0] as MomentInput).format('DD MMM YY') +
        ' - ' +
        moment(range[1] as MomentInput).format('DD MMM YY');
  }

  public get expensiveHook() {
    const {
      selectedDateRange,
      selectedAsOfDate,
      currentYearRange,
        selectedProjects,
    } = this;
    return JSON.stringify([
      selectedDateRange,
      selectedAsOfDate,
      currentYearRange,
        selectedProjects,
    ]);
  }

  public async expensiveCalc() {
    const ref = FilteredDatabase.ref('transactions')
        .includes('accType', ['OI'])
        .includes('project', this.selectedProjects)
        .dateRange(this.currentYearRange);

    const transactionCards = await (await ref.get()).getCards();

    const groupedCards = nestedGroupBy(transactionCards, ['specialAccTypeName']);

    // Extract all unique month-years from your data
    const allMonthYears = this.getMonthYearArray(this.currentYearRange[0], this.currentYearRange[1]);

    // Initialize an object to store data for each group
    const groupData = {};

    for (const group in groupedCards) {
      if (group) {
        // Initialize data for this group
        const groupDataItem = { particular: group };

        // Initialize the month-year data for this group to 0 for all months
        allMonthYears.forEach((monthYear) => {
          groupDataItem[monthYear] = 0;
        });

        for (const card of groupedCards[group]) {
          const docDate = new Date(card.date);
          const monthYear = moment(docDate).format('MMMYY');

          // Add the amount to the corresponding month-year
          groupDataItem[monthYear] += card.amount * -1;
        }

        // Store the groupDataItem in the groupData
        groupData[group] = groupDataItem;
      }
    }

    // Initialize tableFields based on all unique keys (excluding "particular")
    const allKeys = new Set();
    for (const group in groupData) {
      if (group) {
        Object.keys(groupData[group]).forEach((key) => {
          if (key !== 'particular') {
            allKeys.add(key);
          }
        });
      }
    }

    this.tableFields = Array.from(allKeys).map((key) => ({
      key,
      sortable: false,
      formatter: key === 'particular' ? undefined : TableItemFormatter.currency,
    }));

    this.tableFields.unshift({
      key: 'particular',
      sortable: false,
    });

    // Convert groupData into an array and push into this.tableItems
    this.tableItems = Object.values(groupData);
  }


}
