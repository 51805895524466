
import Component from 'vue-class-component';
import SecondaryLineBarChartFilterable from '@/components/reports-v2/components/filterables/SecondaryLineBarChartFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import {DimensionMap, PermissionsGroup, ValueText} from '@/store/models.def';
import echarts from 'echarts';
import moment from 'moment/moment';
import gdbx from '@/store/modules/gdbx';
import {DataViewFormatter} from '@/components/reports-v2/components/elements/charts/helpers/dataViewFormatter';
import {FilteredDatabase} from '@/worker/fd/FilteredDatabase';
import {GobiColor} from '@/helpers/color';
import SecondaryLineBarChart from '@/components/reports-v2/components/elements/charts/SecondaryLineBarChart.vue';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import {TableItemFormatter} from '@/components/reports-v2/components/elements/charts/helpers/tableItemFormatter';
import {Moment, MomentInput} from 'moment';

@Component({
  components: {
    TableFilterable,
    SecondaryLineBarChart,
    SecondaryLineBarChartFilterable,
  },
})
export default class TiarcoChemicalMYearlyComparison extends FilterWidget {

  public filterIds: Array<
      | 'date'
      | 'month'
      | 'dateAsOf'
      | 'dateRange'
      | 'stockItems'
      | 'agents'
      | 'customers'
      | 'suppliers'
      | 'project'
  > = ['suppliers', 'agents', 'dateAsOf', 'month', 'project'];

  public xAxisData: string[] = [];
  public series: echarts.ECharts[] | any[] = [];

  public tableFields: object[] = [];

  public tableItems: object[] = [];

  public exportName(title: string, range: number[]) {
    return title + ' from ' +
        moment(range[0] as MomentInput).format('DD MMM YY') +
        ' - ' +
        moment(range[1] as MomentInput).format('DD MMM YY');
  }

  public get currencySymbol() {
    return gdbx.currencySymbol;
  }

  public get decimal() {
    return gdbx.numDecimal;
  }

  public get currentYearRange(): [number, number] {
    return [
      moment(this.selectedAsOfDate).startOf('year').valueOf(),
      moment(this.selectedAsOfDate).endOf('month').valueOf(),
    ];
  }

  public get dataViewFormatter() {
    return DataViewFormatter.salesTracker;
  }

  public get expensiveHook() {
    const {
      selectedDateRange,
      selectedAsOfDate,
      currentYearRange,
        selectedProjects,
    } = this;
    return JSON.stringify([
      selectedDateRange,
      selectedAsOfDate,
      currentYearRange,
      selectedProjects,
    ]);
  }

  public get monthValuesCurrent() {
    const [d0, d1] = this.currentYearRange.map((d) => moment(d));
    const period = d1.diff(d0, 'month') + 1;
    const result: Moment[] = [];
    for (let i = 0; i < period; i++) {
      result.push(d0.clone());
      d0.add(1, 'month');
    }
    return result;
  }

  public async expensiveCalc() {
    const codeList = ['SL', 'SA', 'CO', 'EP', 'OI', 'EO'];
    const nameList = [
      'SALES',
      'SALES ADJUSTMENT',
      'COST OF GOODS SOLD',
      'EXPENSES',
      'OTHER INCOME',
      'EXTRA INCOME',
    ];

    const map: DimensionMap[] = codeList.map((code, index) => ({
      filterType: 'string',
      filterKey: 'accType',
      value: [code],
      text: nameList[index],
    }));

    const ref = FilteredDatabase.ref('transactions')
        .dateRange(this.currentYearRange);

    const salesRef = FilteredDatabase.ref('transactions')
        .includes('accTypeName', ['SALES'])
        .dateRange(this.currentYearRange)
        .includes('project', this.selectedProjects);

    const salesAdjustmentRef = FilteredDatabase.ref('transactions')
        .includes('accType', ['SA'])
        .includes('project', this.selectedProjects)
        .dateRange(this.currentYearRange);

    const cogsRef = FilteredDatabase.ref('transactions')
        .includes('accTypeName', ['COST OF GOODS SOLD'])
        .includes('project', this.selectedProjects)
        .dateRange(this.currentYearRange);

    const expensesRef = FilteredDatabase.ref('transactions')
        .includes('accTypeName', ['EXPENSES'])
        .includes('project', this.selectedProjects)
        .dateRange(this.currentYearRange);

    const otherIncomeRef = FilteredDatabase.ref('transactions')
        .includes('accTypeName', ['OTHER INCOME'])
        .includes('project', this.selectedProjects)
        .dateRange(this.currentYearRange);

    const extraIncomeRef = FilteredDatabase.ref('transactions')
        .includes('accType', ['EO'])
        .includes('project', this.selectedProjects)
        .dateRange(this.currentYearRange);

    let pa = 0.125;
    let pb = 0;

    const salesDD = await this._loadDimensionByPeriod(
        salesRef,
        'month',
        pa,
        pb,
        'amount',
    );

    pb += pa;
    pa = 0.125;

    const salesAdjustmentDD = await this._loadDimensionByPeriod(
        salesAdjustmentRef,
        'month',
        pa,
        pb,
        'amount',
    );

    pb += pa;
    pa = 0.125;

    const cogsDD = await this._loadDimensionByPeriod(
        cogsRef,
        'month',
        pa,
        pb,
        'amount',
    );

    pb += pa;
    pa = 0.125;

    const expensesDD = await this._loadDimensionByPeriod(
        expensesRef,
        'month',
        pa,
        pb,
        'amount',
    );

    pb += pa;
    pa = 0.125;

    const otherIncomeDD = await this._loadDimensionByPeriod(
        otherIncomeRef,
        'month',
        pa,
        pb,
        'amount',
    );

    const extraIncomeDD = await this._loadDimensionByPeriod(
        extraIncomeRef,
        'month',
        pa,
        pb,
        'amount',
    );

    const grossSales = {
      name: 'Gross Sales',
      data: [] as number[],
      type: 'bar',
      barWidth: '10%',
      barGap: '10%',
      itemStyle: { color: '#404040' },
      smooth: true,
    };

    const grossProfit = {
      name: 'Gross Profit',
      data: [] as number[],
      type: 'bar',
      barWidth: '10%',
      barGap: '10%',
      itemStyle: { color: GobiColor.COLOR1 },
      smooth: true,
    };

    const otherIncome = {
      name: 'Other Income',
      data: [] as number [],
      type: 'bar',
      barWidth: '10%',
      barGap: '10%',
      itemStyle: { color: '#4FA89D' },
      smooth: true,
    };

    const netProfit = {
      name: 'Net Profit',
      data: [] as number[],
      type: 'bar',
      barWidth: '10%',
      barGap: '10%',
      itemStyle: { color: GobiColor.COLOR4 },
      smooth: true,
    };

    const cost = {
      name: 'Net COGS',
      data: [] as number[],
      type: 'bar',
      barWidth: '10%',
      barGap: '10%',
      itemStyle: { color: '#63AED8' },
      smooth: true,
    };

    const expenses = {
      name: 'Expenses',
      data: [] as number[],
      type: 'bar',
      barWidth: '10%',
      barGap: '10%',
      itemStyle: { color: '#F4DE96' },
      smooth: true,
    };

    const forex = {
      name: 'Realised/Unrealised Forex',
      data: [] as number[],
      type: 'bar',
      barWidth: '10%',
      barGap: '10%',
      itemStyle: { color: '#E89246' },
      smooth: true,
    };

    const npAfterEO = {
      name: 'NP after EO',
      data: [] as number[],
      type: 'bar',
      barWidth: '10%',
      barGap: '10%',
      itemStyle: { color: '#A2670D' },
      smooth: true,
    };

    this.xAxisData = [];

    const stockMovements = await Promise.all(
        this.monthValuesCurrent.map(async (m) => {
          return await FilteredDatabase.getStockMovement([
            m.valueOf(),
            m.clone().endOf('month').valueOf(),
          ], this.selectedProjects as [string]);
        }),
    );

    grossSales.data = salesDD.map((sales) => {
      return (sales.sum * -1);
    });

    cost.data = cogsDD.map((cogs, index) => {
      return ((cogs.sum) + (isNaN(stockMovements[index]) ?
              0 : stockMovements[index])
      );
    });

    grossProfit.data = salesDD.map((sales, index) => {
      return ((sales.sum * -1) + (salesAdjustmentDD[index].sum) * -1) -
          cost.data[index];
    });

    otherIncome.data = otherIncomeDD.map((income) => {
      return income.sum * -1;
    });

    netProfit.data = salesDD.map((sales, index) => {
      return (grossProfit.data[index] +
          (otherIncomeDD[index].sum * -1) -
          (expensesDD[index].sum)
      );
    });

    expenses.data = expensesDD.map((expense) => {
      return (expense.sum);
    });

    forex.data = cogsDD.map((cogs, index) => {
      return extraIncomeDD[index].sum * -1;
    });

    npAfterEO.data = cogsDD.map((cogs, index) => {
      return netProfit.data[index] + extraIncomeDD[index].sum * -1;
    });

    this.xAxisData = salesDD.map((a) => {
      return a.text;
    });

    this.series = [grossSales, cost, grossProfit, otherIncome, expenses, forex, npAfterEO];

    this.tableFields = [
      { key: 'month', sortable: false },
      {
        key: 'gross_sales',
        sortable: false,
        formatter: TableItemFormatter.currency,
      },
      {
        key: 'net_COGS',
        sortable: false,
        formatter: TableItemFormatter.currency,
      },
      {
        key: 'g._p.',
        sortable: false,
        formatter: TableItemFormatter.currency,
      },
      {
        key: 'g._p.%',
        sortable: false,
        formatter: TableItemFormatter.percentage,
      },
      {
        key: 'other_income',
        sortable: false,
        formatter: TableItemFormatter.currency,
      },
      {
        key: 'expenses',
        sortable: false,
        formatter: TableItemFormatter.currency,
      },
      {
        key: 'net_profit',
        sortable: false,
        formatter: TableItemFormatter.currency,
      },
      {
        key: 'n._p.%',
        sortable: false,
        formatter: TableItemFormatter.percentage,
      },
      {
        key: 'Realised_/_Unrealised_forex',
        sortable: false,
        formatter: TableItemFormatter.currency,
      },
      {
        key: 'np_after_eo',
        sortable: false,
        formatter: TableItemFormatter.currency,
      },
      {
        key: 'n._p.%_*',
        sortable: false,
        formatter: TableItemFormatter.percentage,
      },
    ];

    const newArr: any[] = [];

    salesDD.forEach((month, index) => {
      return newArr.push({
        'month': month.text,
        'gross_sales': grossSales.data[index],
        'net_COGS': cost.data[index],
        'g._p.': grossProfit.data[index],
        'g._p.%': (grossSales.data[index] !== 0 ?
            (grossProfit.data[index] / grossSales.data[index]) * 100 :
            0),
        'other_income': otherIncome.data[index],
        'expenses': expenses.data[index],
        'net_profit': netProfit.data[index],
        'n._p.%': ((grossSales.data[index] !== 0) ?
            (netProfit.data[index] / grossSales.data[index]) * 100 :
            0),
        'Realised_/_Unrealised_forex': extraIncomeDD[index].sum * -1,
        'np_after_eo': netProfit.data[index] + extraIncomeDD[index].sum * -1,
        'n._p.%_*': ((grossSales.data[index] !== 0) ?
            ((netProfit.data[index] + extraIncomeDD[index].sum * -1)
                / grossSales.data[index] * 100) :
            0),
      });
    });

    this.tableItems = newArr;

    this.saveHistory(
        'series',
        'series2',
        'xAxisData',
        'xAxisDate2',
        'tableFields',
        'tableItems',
    );
  }

}
