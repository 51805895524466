
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import Component from 'vue-class-component';
import moment, {MomentInput} from 'moment';
import {FilteredDatabase} from '@/worker/fd/FilteredDatabase';
import {nestedGroupBy} from '@/util/nestedGroupBy';
import { TableItemFormatter } from '../../elements/charts/helpers/tableItemFormatter';
import IncludeCreditNoteToggle from '@/components/IncludeCreditNoteToggle.vue';
import IncludeDebitNoteToggle from '@/components/IncludeDebitNoteToggle.vue';
import settingx from '@/store/modules/settingx';
import Checkbox from 'primevue/checkbox';
import DateRangeFilter from '@/components/reports-v2/components/filters/DateRangeFilter.vue';
import TableCollapseToggle from '@/components/TableCollapseToggle.vue';

@Component({
    components: {
        TableCollapseToggle,
        DateRangeFilter,
        Checkbox,
        IncludeDebitNoteToggle,
        IncludeCreditNoteToggle,
        TableFilterable,
    },
})
export default class TiarcoChemicalMOtherIncomeBreakdownTable extends FilterWidget {
    public filterIds: Array<
        | 'date'
        | 'month'
        | 'dateAsOf'
        | 'dateRange'
        | 'stockItems'
        | 'agents'
        | 'customers'
        | 'suppliers'
        | 'project'
    > = ['suppliers', 'agents', 'dateAsOf', 'month', 'project'];

    public quantityToggle = false;
    public manualDateRange: [number, number] = [this.selectedDateRange[0], this.selectedDateRange[1]];
    public totalTableFields: object[] = [];
    public totalTableData: object[] = [];

    public get startDate() {
        return this.manualDateRange[0];
    }

    public get includeCreditNote() {
        return settingx.now.includeCreditNote;
    }

    public get includeDebitNote() {
        return settingx.now.includeDebitNote;
    }

    public tableItems: object[] = [];
    public tableFields: object[] = [];

    public get currentYearRange(): [number, number] {
        return [
            moment(this.selectedAsOfDate).startOf('year').valueOf(),
            moment(this.selectedAsOfDate).endOf('month').valueOf(),
        ];
    }

    public getMonthYearArray(startDate, endDate) {
        const start = moment(startDate);
        const end = moment(endDate);

        const monthYearArray: string[] = [];

        while (start.isBefore(end) || start.isSame(end, 'month')) {
            monthYearArray.push(start.format('MMMYY'));
            start.add(1, 'month');
        }

        return monthYearArray;
    }

    public exportName(range: number[]) {
        return 'Tiarco Yearly Sales Analysis By Item Code' + ' from ' +
            moment(range[0] as MomentInput).format('DD MMM YY') +
            ' - ' +
            moment(range[1] as MomentInput).format('DD MMM YY');
    }

    public get expensiveHook() {
        const {
            manualDateRange,
            selectedDateRange,
            selectedAsOfDate,
            currentYearRange,
            selectedProjects,
            includeCreditNote,
            includeDebitNote,
            quantityToggle,
        } = this;
        return JSON.stringify([
            manualDateRange,
            selectedDateRange,
            selectedAsOfDate,
            currentYearRange,
            selectedProjects,
            includeCreditNote,
            includeDebitNote,
            quantityToggle,
        ]);
    }

    public async expensiveCalc() {
        const accTypes = ['IV', 'CS'];

        if (this.includeDebitNote) {
            accTypes.push('DN');
        }

        if (this.includeCreditNote) {
            accTypes.push('CN');
        }

        const ref = FilteredDatabase.ref('sales')
            .includes('docType', accTypes)
            .dateRange(this.manualDateRange);

        const cnRef = FilteredDatabase.ref('creditNotes')
            .dateRange(this.manualDateRange);

        const customerRef = await (await (FilteredDatabase.ref('globalCustomers')).get()).getCards();
        const stockRef = await (await (FilteredDatabase.ref('globalStocks')).get()).getCards();

        const transactionCards = await (await ref.get()).getCards();
        const groupedCards = nestedGroupBy(transactionCards, ['code', 'itemCode']);

        const cnCards = await(await cnRef.get()).getCards();
        const groupedCnCards = nestedGroupBy(cnCards, ['code', 'itemCode']);

        // Extract all unique month-years from your data
        const allMonthYears = this.getMonthYearArray(this.manualDateRange[0], this.manualDateRange[1]);

        if (this.totalTableData.length) {
            this.totalTableData = [];
        }

        // Initialize an object to store data for each group
        const groupData: any[] = [];

        const tempTotalData: {
            month?: string,
        } = {
            month: 'Total',
        };

        allMonthYears.forEach((monthYear) => {
            tempTotalData[monthYear] = 0;
        });

        for (const customerGroup in groupedCards) {
            if (customerGroup) {
                for (const itemGroup in groupedCards[customerGroup]) {
                    if (itemGroup) {
                        // Initialize data for this group
                        const groupDataItem = {
                            company_name: customerRef.find((x) => {
                                return x.code === customerGroup;
                            }).companyName,
                            item_code: itemGroup,
                            item_desc: stockRef.find((x) => {
                                return x.itemCode === itemGroup;
                            }).itemDesc,
                            total_value: 0,
                        };

                        // Initialize the month-year data for this group to 0 for all months
                        allMonthYears.forEach((monthYear) => {
                            groupDataItem[monthYear] = 0;
                        });

                        for (const card of groupedCards[customerGroup][itemGroup]) {
                            const docDate = new Date(card.date);
                            const monthYear = moment(docDate).format('MMMYY');

                            groupDataItem[monthYear] += !this.quantityToggle ? card.amount : card.quantity;

                            groupDataItem.total_value += !this.quantityToggle ? card.amount : card.quantity;

                            tempTotalData[monthYear] += !this.quantityToggle ? card.amount : card.quantity;

                            if (
                                this.includeCreditNote &&
                                groupedCnCards.hasOwnProperty(customerGroup) &&
                                groupedCnCards[customerGroup].hasOwnProperty(itemGroup)
                            ) {
                                // Loop through the groupedCnCards for this customerGroup/itemGroup
                                for (const cnCard of groupedCnCards[customerGroup][itemGroup]) {
                                    const cnDocDate = new Date(cnCard.date);
                                    const cnMonthYear = moment(cnDocDate).format('MMMYY');

                                    // If the monthYear matches, add the cnCard.amount to the respective monthYear
                                    if (monthYear === cnMonthYear) {
                                        groupDataItem[monthYear] -= !this.quantityToggle ?
                                            cnCard.amount :
                                            cnCard.quantity;
                                        groupDataItem.total_value -= !this.quantityToggle ?
                                            cnCard.amount :
                                            cnCard.quantity;
                                        tempTotalData[monthYear] -= !this.quantityToggle ?
                                            cnCard.amount :
                                            cnCard.quantity;
                                    }
                                }
                            }
                        }

                        // Store the groupDataItem in the groupData
                        groupData.push(groupDataItem);
                    }
                }
            }
        }

        // Initialize tableFields based on all unique keys (excluding "particular")
        const allKeys = new Set();
        for (const group in groupData) {
            if (group) {
                Object.keys(groupData[group]).forEach((key) => {
                    if (key !== 'particular') {
                        allKeys.add(key);
                    }
                });
            }
        }

        const allTotalKeys = new Set();
        for (const group in groupData) {
            if (group) {
                allMonthYears.forEach((key) => {
                    allTotalKeys.add(key);
                });
            }
        }

        this.tableFields = Array.from(allKeys).map((key) => ({
            key,
            sortable: false,
            formatter: key === 'item_code' || key === 'company_name' || key === 'item_desc' ?
                undefined :
                !this.quantityToggle ? TableItemFormatter.currency : TableItemFormatter.basicWithDecimals,
        }));

        this.totalTableFields = Array.from(allTotalKeys).map((key) => ({
            key,
            sortable: false,
            formatter: !this.quantityToggle ? TableItemFormatter.currency : TableItemFormatter.basicWithDecimals,

        }));

        this.totalTableFields.unshift(
            {
                key: 'month',
                sortable: false,
                formatter: undefined,
            },
        );
        // this.tableFields.unshift({
        //     key: 'item_code',
        //     sortable: false,
        // });

        this.tableFields.splice(3, 1);
        //
        // this.tableFields.unshift({s
        //     key: 'company_name',
        //     sortable: false,
        // });

        this.tableFields.push({
            key: 'total_value',
            sortable: false,
            formatter: !this.quantityToggle ? TableItemFormatter.currency : TableItemFormatter.basicWithDecimals,
        });


        this.totalTableData.push(tempTotalData);

        // Convert groupData into an array and push into this.tableItems
        this.tableItems = Object.values(groupData);
    }


}
