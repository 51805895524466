
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import Component from 'vue-class-component';
import moment, {MomentInput} from 'moment';
import {FilteredDatabase} from '@/worker/fd/FilteredDatabase';
import {nestedGroupBy} from '@/util/nestedGroupBy';
import { TableItemFormatter } from '../../elements/charts/helpers/tableItemFormatter';
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import TableCollapseToggle from '@/components/TableCollapseToggle.vue';

interface StockCard {
    itemCode: string,
    itemDesc: string,
}

interface Totals {
    sub_total: number,
    total_discount: number,
    grand_total: number,
}

@Component({
    components: {
        TableCollapseToggle,
        SelectFilter,
        TableFilterable,
    },
})
export default class TiarcoChemicalMOtherIncomeBreakdownTable extends FilterWidget {
    public filterIds: Array<
        | 'date'
        | 'month'
        | 'dateRange'
        | 'stockItems'
        | 'itemCode'
    > = ['dateRange', 'month', 'stockItems', 'itemCode'];

    public tableItems: object[] = [];
    public tableFields: object[] = [];
    public periodTotalFields: any[] = [
        {
            key: 'sub_total',
            formatter: TableItemFormatter.currency,
        },
        {
            key: 'total_discount',
            formatter: TableItemFormatter.currency,
        },
        {
            key: 'grand_total',
            formatter: TableItemFormatter.currency,
        },
    ];
    public periodTotalData: Totals[] = [];
    public itemCode: string = 'Others';
    public stockCards: StockCard[] = [];
    public options: any[] | undefined = [];

    public exportName(range: number[]) {
        return 'NYCX Discount By Item Code' + ' from ' +
            moment(range[0] as MomentInput).format('DD MMM YY') +
            ' - ' +
            moment(range[1] as MomentInput).format('DD MMM YY');
    }

    public get generateTitleText() {
        const stockCard = this.stockCards.find((card) => card.itemCode === this.itemCode);
        const itemDesc = stockCard ? stockCard.itemDesc : null;
        return `Discount Table for [${itemDesc}]`;
    }

    public get expensiveHook() {
        const {
            selectedDateRange,
            itemCode,
        } = this;
        return JSON.stringify([
            selectedDateRange,
            itemCode,
        ]);
    }

    public async expensiveCalc() {

        const ref = FilteredDatabase.ref('sales')
            .dateRange(this.selectedDateRange)
            .includes('itemCode', [this.itemCode]);

        const stockItemRef = FilteredDatabase.ref('globalStocks');

        const salesCards = await (await ref.get()).getCards();

        const nestedCards = nestedGroupBy(salesCards, ['itemCode']);

        const stockCard = await(await stockItemRef.get()).getCards();

        this.stockCards = stockCard;

        this.options = stockCard.map((card) => {
            return {
                text: card.itemCode,
                value: card.itemCode,
            };
        });


        const newArray: any = [];

        if (salesCards.length) {
            nestedCards[this.itemCode].forEach((card: {
                date: string | number;
                docNo: any;
                quantity: number;
                amount: number;
                discount: any;
            }) => {
                newArray.push({
                    date: moment(card.date).format('DD/MM/YY'),
                    doc_no: card.docNo,
                    qty: card.quantity,
                    unit_price: (card.amount / (card.quantity || 1)),
                    sub_total: Math.round(card.amount),
                    disc: Math.round(card.discount),
                    total: Math.round((card.amount - card.discount)),
                });
            });
        }

        this.tableItems = newArray;

        this.periodTotalData = [
            {
                sub_total: newArray.reduce((a: any, b: { sub_total: any; }) => a + b.sub_total, 0),
                total_discount: newArray.reduce((a: any, b: { disc: any; }) => a + b.disc, 0),
                grand_total: newArray.reduce((a: any, b: { total: any; }) => a + b.total, 0),
            },
        ];

        this.tableFields = [
            {
                key: 'date',
                sortable: false,
            },
            {
                key: 'doc_no',
                sortable: false,
            },
            {
                key: 'qty',
                sortable: false,
            },
            {
                key: 'sub_total',
                sortable: false,
                formatter: TableItemFormatter.currency,
            },
            {
                key: 'disc',
                sortable: false,
                formatter: TableItemFormatter.currency,
            },
            {
                key: 'total',
                sortable: false,
                formatter: TableItemFormatter.currency,
            },

        ];
    }


}
