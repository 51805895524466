
import {Component} from 'vue-property-decorator';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import {FilteredDatabase} from '@/worker/fd/FilteredDatabase';
import {PermissionsGroup} from '@/store/models.def';
import Checkbox from 'primevue/checkbox';
import moment from 'moment';
import {nestedGroupBy} from '@/util/nestedGroupBy';
import {TableItemFormatter} from '@/components/reports-v2/components/elements/charts/helpers/tableItemFormatter';

@Component({
    components: {
        TableFilterable,
        Checkbox,
    },
})
export default class NonStopAutoDashboard extends FilterWidget {

    public get permissionIds(): PermissionsGroup[] {
        return ['stocks'];
    }

    public get exportFileName() {
        const formattedDate = moment(this.selectedAsOfDate).format('DD MMM YY');
        return 'Maintain Stock Item' + '_' + 'As Of ' + formattedDate;
    }

    public get dateFormatted() {
        return [this.selectedAsOfDate];
    }

    public get expensiveHook() {
        const { selectedAsOfDate } = this;
        return JSON.stringify([
            selectedAsOfDate,
        ]);
    }

    public get date() {
        return this.selectedAsOfDate;
    }

    public get newTableFields(): any [] {
        const table = [
            {
                key: 'barcode',
                sortable: true,
                stickyColumn: true,
            },
            {
                key: 'item_desc',
                sortable: true,
                stickyColumn: true,
            },
            {
                key: 'item_code',
                sortable: true,
                stickyColumn: true,
            },
            {
                key: 'bal_qty',
                sortable: true,
                stickyColumn: true,
            },
            {
                key: 'uom',
                sortable: true,
                stickyColumn: true,
            },
            {
                key: 'ref_cost',
                sortable: true,
                stickyColumn: true,
                formatter: TableItemFormatter.currency,
            },
            {
                key: 'ref_price',
                sortable: true,
                stickyColumn: true,
                formatter: TableItemFormatter.currency,
            },
            {
                key: 'remark',
                sortable: true,
                stickyColumn: true,
            },
        ];
        return table;
    }

    public filterIds: Array<
        | 'dateAsOf'
    > = ['dateAsOf'];

    public tableItems: any[] = [];
    public tableFields: any[] = [];
    public stockCards: any [] = [];
    public result: any[] = [];
    public initialTableItems: any [] = [];
    public afterCalculationTableItems: any [] = [];

    // public dateFormatter(month, year) {
    //     return moment(`${year}-${month}`, 'YYYY-MM').format('x');
    // }
    //
    // public thousandFormatter(x: number) {
    //     return x ? x.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : x;
    // }

    public stockAvailabilityTable() {
        const nestedArray = nestedGroupBy(this.initialTableItems, ['itemCode']);

        const newArray: any [] = [];

        for (const item of Object.keys(nestedArray)) {
            const eachItem = nestedArray[item][nestedArray[item].length - 1];

            const obj = {
                barcode: eachItem.barCode,
                item_desc: eachItem.itemDesc,
                item_code: eachItem.itemCode,
                bal_qty: eachItem.balQty,
                uom: eachItem.uom,
                ref_cost: eachItem.refCost,
                ref_price: eachItem.refPrice,
                remark: eachItem.remark1,
            };

            newArray.push(obj);
        }

        this.loading = false;
        return this.afterCalculationTableItems = newArray;
    }

    public async expensiveCalc() {
        const table = await FilteredDatabase.ref('diyMaintainStockItem')
            .get();
        const desc = await FilteredDatabase.ref('globalStocks').get();
        this.stockCards = await desc.getCards();
        this.initialTableItems = await table.getCards();

        this.stockAvailabilityTable();
    }
}
