
import {Component, Watch} from 'vue-property-decorator';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import NYCXDiscountByItemCodeTable from '@/components/reports-v2/components/codedWidgets/customisation/NYCXDiscountByItemCodeTable.vue';
import NYCXDiscountByTotalTable from '@/components/reports-v2/components/codedWidgets/customisation/NYCXDiscountByTotalTable.vue';

@Component({
    components: {
        NYCXDiscountByTotalTable,
        NYCXDiscountByItemCodeTable,
    },
})

export default class NYCXCustomDashboard extends FilterWidget {

}
