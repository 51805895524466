
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import StickyFilterContainer from '@/components/reports-v2/components/pages/StickyFilterContainer.vue';
import YTDSalesNumberDisplayer from '@/components/reports-v2/components/codedWidgets/Copy/YTDSalesNumberDisplayer.vue';
import MonthSalesNumberDisplayer from '@/components/reports-v2/components/codedWidgets/Copy/MonthSalesNumberDisplayer.vue';
import MonthSalesProfitNumberDisplayer from '@/components/reports-v2/components/codedWidgets/Copy/MonthSalesProfitNumberDisplayer.vue';
import MonthTopAgentTable from '@/components/reports-v2/components/codedWidgets/Copy/MonthlyTopAgentDisplayer.vue';
import MonthTopCustomerTable from '@/components/reports-v2/components/codedWidgets/Copy/MonthTopCustomerTable.vue';
import SalesTrendBarLineChart from '@/components/reports-v2/components/codedWidgets/Copy/SalesTrendBarLineChart.vue';
import SalesAnalysisTreeMap from '@/components/reports-v2/components/codedWidgets/Copy/SalesAnalysisTreeMap.vue';
import userx from '@/store/modules/userx';
import router from '@/router';
import moment from 'moment';
import DateFilter from '@/components/reports-v2/components/filters/DateFilter.vue';
import DateRangeFilter from '@/components/reports-v2/components/filters/DateRangeFilter.vue';
import gdbx from '@/store/modules/gdbx';
import LastSync from '@/components/LastSync.vue';
import StockItemSalesByCustomerTable from '@/components/reports-v2/components/codedWidgets/Copy/StockItemSalesByCustomerTable.vue';
import BasePageContainer from '@/components/reports-v2/pages/BasePageContainer.vue';

@Component({
	components: {
		YTDSalesNumberDisplayer,
		MonthSalesNumberDisplayer,
		MonthSalesProfitNumberDisplayer,
		StickyFilterContainer,
		DateFilter,
    DateRangeFilter,
		MonthTopAgentTable,
		MonthTopCustomerTable,
		SalesTrendBarLineChart,
		SalesAnalysisTreeMap,
		LastSync,
		StockItemSalesByCustomerTable,
	},
})
export default class SalesOverview extends BasePageContainer {
	public selectedDay: number = moment().endOf('day').valueOf();

  public selectedDateRange: [number, number] = [
    moment().startOf('month').valueOf(),
    moment().endOf('day').valueOf(),
  ];

  public get selectedDateRangeStartEnd() {
    return [
      moment(this.selectedDateRange[1]).startOf('month').valueOf(),
      moment(this.selectedDateRange[1]).endOf('day').valueOf(),
    ];
  }

	public mounted() {
		this.track();
	}

	public track() {
		this.$ga.set('userId', userx.user!.uid + '_' + userx.user!.email);
		this.$ga.page(router);
	}

	public get selectedAsOfDate() {
		return moment(this.selectedDateRange[1]).endOf('month').valueOf();
	}

	public get selectedCurrentMonthRange(): [number, number] {
		return [
			moment(this.selectedDateRange[1]).startOf('month').valueOf(),
			moment(this.selectedDateRange[1]).endOf('month').valueOf(),
		];
	}

	public get currentYearRange(): [number, number] {
		return [
			moment(this.selectedDay).startOf('year').valueOf(),
			moment(this.selectedDay).endOf('year').valueOf(),
		];
	}

  public get selectedYearToDateRange(): [number, number] {
    return [
      moment(this.selectedDateRange[1]).add(-12, 'month').valueOf(),
      moment(this.selectedDateRange[1]).endOf('month').valueOf(),
    ];
  }

	public get selectedAgents() {
		return gdbx.allAgents.map((vt) => vt.value);
	}

	public get selectedStockItems() {
		return gdbx.allStockItems.map((vt) => vt.value);
	}

	public get selectedCustomers() {
		return gdbx.allCustomers.map((vt) => vt.value);
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
