
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import AmountDisplayerFilterable from '@/components/reports-v2/components/filterables/AmountDisplayerFilterable.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '../FilterWidget.vue';
import {
  CustomerSupplierTradeCard,
  PermissionsGroup,
  SalesDetailCard,
} from '@/store/models.def';
import moment from 'moment';
import IncludeCreditNoteToggle from '@/components/IncludeCreditNoteToggle.vue';
import IncludeDebitNoteToggle from '@/components/IncludeDebitNoteToggle.vue';
import settingx from '@/store/modules/settingx';

@Component({
  components: {
    AmountDisplayerFilterable,
    IncludeCreditNoteToggle,
    IncludeDebitNoteToggle,
  },
})
export default class YTDSalesNumberDisplayer extends FilterWidget {
  public filterIds: Array<
      | 'date'
      | 'dateAsOf'
      | 'dateRange'
      | 'stockItems'
      | 'agents'
      | 'customers'
      | 'suppliers'
  > = [];

  public salesAmount = 0;

  public customersCount = 0;
  public activeCustomersCount = 0;
  public allTimeSales = '' || 0;
  public servingYear = '' || 0;

  @Watch('customersCount', { immediate: true })
  public onCustomerCountChange(newVal) {
    this.$emit('returnCustomerCount', this.customersCount);
  }

  @Watch('servingYear', { immediate: true })
  public onServingYearChange(newVal) {
    this.$emit('returnServingYear', this.servingYear);
  }

  public get oneYearBeforeRange(): [number, number] {
    return [
      moment(this.selectedAsOfDate).add(-1, 'year').startOf('month').valueOf(),
      moment(this.selectedAsOfDate).endOf('month').valueOf(),
    ];
  }

  public get includeCreditNote() {
    return settingx.now.includeCreditNote;
  }

  public get includeDebitNote() {
    return settingx.now.includeDebitNote;
  }

  public get permissionIds(): PermissionsGroup[] {
    return ['customers', 'sales'];
  }

  public get expensiveHook() {
    const {
      selectedDateRange,
      selectedAsOfDate,
      selectedAgents,
      includeCreditNote,
      includeDebitNote,
    } = this;
    return JSON.stringify([
      selectedDateRange,
      selectedAsOfDate,
      selectedAgents,
      includeCreditNote,
      includeDebitNote,
    ]);
  }

  public async expensiveCalc() {
    const tradeRef = FilteredDatabase.ref('customerTrades')
        .agents(this.selectedAgents)
        .dateRange(this.selectedDateRange)
        .includes('docType', ['IV']);

    const tradeCNRef = FilteredDatabase.ref('customerTrades')
        .agents(this.selectedAgents)
        .dateRange(this.selectedDateRange)
        .includes('docType', ['CN']);

    const tradeDNRef = FilteredDatabase.ref('customerTrades')
        .agents(this.selectedAgents)
        .dateRange(this.selectedDateRange)
        .includes('docType', ['DN']);

    const tradeRefHead = await tradeRef.get();
    const tradeCNRefHead = await tradeCNRef.get();
    const tradeDNRefHead = await tradeDNRef.get();

    const sales = await tradeRefHead.getSum('amount');
    const creditNote = await tradeCNRefHead.getSum('amount');
    const debitNote = await tradeDNRefHead.getSum('amount');

    this.salesAmount = sales - (this.includeCreditNote ? creditNote : 0) + (this.includeDebitNote ? debitNote : 0);

    // Active customers

    const globalRef = FilteredDatabase.ref('globalCustomers')
        .agents(this.selectedAgents)
        .numberRange('firstDeal', [
          ['>', Number.NEGATIVE_INFINITY],
          ['<=', this.selectedAsOfDate],
        ]);

    let globalCards: CustomerSupplierTradeCard[] = await (
        await globalRef.get()
    ).getCards();

    globalCards = globalCards.filter((item) => item.firstDeal !== null);

    this.customersCount = globalCards.length;

    const ivdnRef = FilteredDatabase.ref('customerIvDns')
        .agents(this.selectedAgents)
        .dateRange(this.oneYearBeforeRange);

    this.activeCustomersCount = [
      ...(await (await ivdnRef.get()).getSet('code')),
    ].length;

    const salesRef = FilteredDatabase.ref('sales')
        .agents(this.selectedAgents)
        .dateAsOf(this.selectedAsOfDate);

    const salesCards: SalesDetailCard[] = await (
        await salesRef.get()
    ).getCards();

    const servingYearsList = salesCards.map((item) => moment(item.date).year());

    this.servingYear = [...new Set(servingYearsList)].length;

    this.saveHistory('salesAmount');
  }
}
