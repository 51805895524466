
import Component from 'vue-class-component';
import SecondaryLineBarChartFilterable from '@/components/reports-v2/components/filterables/SecondaryLineBarChartFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import {DimensionMap, PermissionsGroup, ValueText} from '@/store/models.def';
import echarts from 'echarts';
import moment, {Moment, MomentInput} from 'moment/moment';
import gdbx from '@/store/modules/gdbx';
import {DataViewFormatter} from '@/components/reports-v2/components/elements/charts/helpers/dataViewFormatter';
import {FilteredDatabase} from '@/worker/fd/FilteredDatabase';
import {GobiColor} from '@/helpers/color';
import SecondaryLineBarChart from '@/components/reports-v2/components/elements/charts/SecondaryLineBarChart.vue';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import {TableItemFormatter} from '@/components/reports-v2/components/elements/charts/helpers/tableItemFormatter';

@Component({
  components: {
    TableFilterable,
    SecondaryLineBarChart,
    SecondaryLineBarChartFilterable,
  },
})
export default class TiarcoChemicalMYearlyComparison extends FilterWidget {

  public filterIds: Array<
      | 'date'
      | 'month'
      | 'dateAsOf'
      | 'dateRange'
      | 'stockItems'
      | 'agents'
      | 'customers'
      | 'suppliers'
      | 'project'
  > = ['suppliers', 'agents', 'dateAsOf', 'month', 'project'];

  public xAxisData: string[] = [];
  public xAxisData2: string[] = [];
  public series: echarts.ECharts[] | any[] = [];
  public series2: echarts.ECharts[] | any[] = [];

  public tableFields: object[] = [];

  public tableItems: object[] = [];

  public exportName(title: string, range: number[]) {
    return title + ' from ' +
        moment(range[0] as MomentInput).format('DD MMM YY') +
        ' - ' +
        moment(range[1] as MomentInput).format('DD MMM YY');
  }

  public get currencySymbol() {
    return gdbx.currencySymbol;
  }

  public get decimal() {
    return gdbx.numDecimal;
  }

  public get currentYearRange(): [number, number] {
    return [
        moment(this.selectedAsOfDate).startOf('year').valueOf(),
        moment(this.selectedAsOfDate).endOf('month').valueOf(),
    ];
  }

  public get pastYearRange(): [number, number] {
    return [
        moment(this.currentYearRange[0]).add(-1, 'year').valueOf(),
        moment(this.currentYearRange[1]).add(-1, 'year').valueOf(),
    ];
  }

  public get titleName(): string {
    return 'Yearly Comparison (Same Period)';
  }

  public get dataViewFormatter() {
    return DataViewFormatter.salesTracker;
  }

  public get expensiveHook() {
    const {
      selectedDateRange,
      selectedAsOfDate,
      currentYearRange,
      pastYearRange,
        selectedProjects,
    } = this;
    return JSON.stringify([
      selectedDateRange,
      selectedAsOfDate,
      currentYearRange,
      pastYearRange,
        selectedProjects,
    ]);
  }

  public get monthValuesCurrent() {
    const [d0, d1] = this.currentYearRange.map((d) => moment(d));
    const period = d1.diff(d0, 'month') + 1;
    const result: Moment[] = [];
    for (let i = 0; i < period; i++) {
      result.push(d0.clone());
      d0.add(1, 'month');
    }
    return result;
  }

  public get monthValuesPast() {
    const [d0, d1] = this.pastYearRange.map((d) => moment(d));
    const period = d1.diff(d0, 'month') + 1;
    const result: Moment[] = [];
    for (let i = 0; i < period; i++) {
      result.push(d0.clone());
      d0.add(1, 'month');
    }
    return result;
  }

  public async expensiveCalc() {
    const codeList = ['SL', 'SA', 'CO', 'EP', 'OI', 'EO'];
    const nameList = [
      'SALES',
      'SALES ADJUSTMENT',
      'COST OF GOODS SOLD',
      'EXPENSES',
      'OTHER INCOME',
      'EXTRA INCOME',
    ];

    const map: DimensionMap[] = codeList.map((code, index) => ({
      filterType: 'string',
      filterKey: 'accType',
      value: [code],
      text: nameList[index],
    }));

    const ref = FilteredDatabase.ref('transactions')
        .dateRange(this.currentYearRange);

    const ref2 = FilteredDatabase.ref('transactions')
        .dateRange(this.pastYearRange);

    const salesRef = FilteredDatabase.ref('transactions')
        .includes('accTypeName', ['SALES'])
        .dateRange(this.currentYearRange)
        .includes('project', this.selectedProjects);

    const salesRef2 = FilteredDatabase.ref('transactions')
        .includes('accTypeName', ['SALES'])
        .dateRange(this.pastYearRange)
        .includes('project', this.selectedProjects);

    const salesAdjustmentRef = FilteredDatabase.ref('transactions')
        .includes('accType', ['SA'])
        .dateRange(this.currentYearRange)
        .includes('project', this.selectedProjects);

    const salesAdjustmentRef2 = FilteredDatabase.ref('transactions')
        .includes('accType', ['SA'])
        .dateRange(this.pastYearRange)
        .includes('project', this.selectedProjects);

    const cogsRef = FilteredDatabase.ref('transactions')
        .includes('accTypeName', ['COST OF GOODS SOLD'])
        .dateRange(this.currentYearRange)
        .includes('project', this.selectedProjects);

    const cogsRef2 = FilteredDatabase.ref('transactions')
        .includes('accTypeName', ['COST OF GOODS SOLD'])
        .dateRange(this.pastYearRange)
        .includes('project', this.selectedProjects);

    const expensesRef = FilteredDatabase.ref('transactions')
        .includes('accTypeName', ['EXPENSES'])
        .dateRange(this.currentYearRange)
        .includes('project', this.selectedProjects);

    const expensesRef2 = FilteredDatabase.ref('transactions')
        .includes('accTypeName', ['EXPENSES'])
        .dateRange(this.pastYearRange)
        .includes('project', this.selectedProjects);

    const otherIncomeRef = FilteredDatabase.ref('transactions')
        .includes('accType', ['OI'])
        .dateRange(this.currentYearRange)
        .includes('project', this.selectedProjects);

    const otherIncomeRef2 = FilteredDatabase.ref('transactions')
        .includes('accType', ['OI'])
        .dateRange(this.pastYearRange)
        .includes('project', this.selectedProjects);

    const extraIncomeRef = FilteredDatabase.ref('transactions')
        .includes('accType', ['EO'])
        .dateRange(this.currentYearRange)
        .includes('project', this.selectedProjects);

    const extraIncomeRef2 = FilteredDatabase.ref('transactions')
        .includes('accType', ['EO'])
        .dateRange(this.pastYearRange)
        .includes('project', this.selectedProjects);

    let pa = 0.125;
    let pb = 0;

    const salesDD = await this._loadDimensionByPeriod(
        salesRef,
        'month',
        pa,
        pb,
        'amount',
    );

    pb += pa;
    pa = 0.125;

    const salesDD2 = await this._loadDimensionByPeriod(
        salesRef2,
        'month',
        pa,
        pb,
        'amount',
    );

    pb += pa;
    pa = 0.125;

    const salesAdjustmentDD = await this._loadDimensionByPeriod(
        salesAdjustmentRef,
        'month',
        pa,
        pb,
        'amount',
    );

    pb += pa;
    pa = 0.125;

    const salesAdjustmentDD2 = await this._loadDimensionByPeriod(
        salesAdjustmentRef2,
        'month',
        pa,
        pb,
        'amount',
    );

    pb += pa;
    pa = 0.125;

    const cogsDD = await this._loadDimensionByPeriod(
        cogsRef,
        'month',
        pa,
        pb,
        'amount',
    );

    pb += pa;
    pa = 0.125;

    const cogsDD2 = await this._loadDimensionByPeriod(
        cogsRef2,
        'month',
        pa,
        pb,
        'amount',
    );

    pb += pa;
    pa = 0.125;

    const expensesDD = await this._loadDimensionByPeriod(
        expensesRef,
        'month',
        pa,
        pb,
        'amount',
    );

    pb += pa;
    pa = 0.125;

    const expensesDD2 = await this._loadDimensionByPeriod(
        expensesRef2,
        'month',
        pa,
        pb,
        'amount',
    );

    const otherIncomeDD = await this._loadDimensionByPeriod(
        otherIncomeRef,
        'month',
        pa,
        pb,
        'amount',
    );

    const otherIncomeDD2 = await this._loadDimensionByPeriod(
        otherIncomeRef2,
        'month',
        pa,
        pb,
        'amount',
    );

    const extraIncomeDD = await this._loadDimensionByPeriod(
        extraIncomeRef,
        'month',
        pa,
        pb,
        'amount',
    );

    const extraIncomeDD2 = await this._loadDimensionByPeriod(
        extraIncomeRef2,
        'month',
        pa,
        pb,
        'amount',
    );

    const grossSales = {
      name: 'Gross Sales',
      data: [] as number[],
      type: 'bar',
      barWidth: '10%',
      barGap: '10%',
      itemStyle: { color: '#404040' },
      smooth: true,
    };

    const grossProfit = {
      name: 'Gross Profit',
      data: [] as number[],
      type: 'bar',
      barWidth: '10%',
      barGap: '10%',
      itemStyle: { color: GobiColor.COLOR1 },
      smooth: true,
    };

    const otherIncome = {
      name: 'Other Income',
      data: [] as number [],
      type: 'bar',
      barWidth: '10%',
      barGap: '10%',
      itemStyle: { color: '#4FA89D' },
      smooth: true,
    };

    const netProfit = {
      name: 'Net Profit',
      data: [] as number[],
      type: 'bar',
      barWidth: '10%',
      barGap: '10%',
      itemStyle: { color: GobiColor.COLOR4 },
      smooth: true,
    };

    const cost = {
      name: 'Net COGS',
      data: [] as number[],
      type: 'bar',
      barWidth: '10%',
      barGap: '10%',
      itemStyle: { color: '#63AED8' },
      smooth: true,
    };

    const expenses = {
      name: 'Expenses',
      data: [] as number[],
      type: 'bar',
      barWidth: '10%',
      barGap: '10%',
      itemStyle: { color: '#F4DE96' },
      smooth: true,
    };

    const forex = {
      name: 'Realised/Unrealised Forex',
      data: [] as number[],
      type: 'bar',
      barWidth: '10%',
      barGap: '10%',
      itemStyle: { color: '#E89246' },
      smooth: true,
    };

    const npAfterEO = {
      name: 'NP after EO',
      data: [] as number[],
      type: 'bar',
      barWidth: '10%',
      barGap: '10%',
      itemStyle: { color: '#A2670D' },
      smooth: true,
    };

    this.xAxisData = [];

    const stockMovements1 = await Promise.all(
        this.monthValuesCurrent.map(async (m) => {
          return await FilteredDatabase.getStockMovement([
            m.valueOf(),
            m.clone().endOf('month').valueOf(),
          ], this.selectedProjects as [string]);
        }),
    );

    const stockMovements2 = await Promise.all(
        this.monthValuesPast.map(async (m) => {
          return await FilteredDatabase.getStockMovement([
            m.valueOf(),
            m.clone().endOf('month').valueOf(),
          ], this.selectedProjects as [string]);
        }),
    );


    const stockBalance1 = stockMovements1.reduce((a, b) => a + b, 0);
    const stockBalance2 = stockMovements2.reduce((a, b) => a + b, 0);

    grossSales.data = [
      (salesDD.reduce(( a, b) => a + b.sum, 0) * -1),
      (salesDD2.reduce(( a, b) => a + b.sum, 0) * -1),
    ];

    grossProfit.data = [
      (salesDD.reduce((a, b) => a + b.sum, 0) * -1 +
          salesAdjustmentDD.reduce((a, b) => a + b.sum, 0) * -1) +
      ((cogsDD.reduce((a, b) => a + b.sum, 0) * -1 +
          stockMovements1.reduce((a, b) => a + b, 0) * -1)),
      (salesDD2.reduce((a, b) => a + b.sum, 0) * -1 +
          salesAdjustmentDD2.reduce((a, b) => a + b.sum, 0) * -1) +
      ((cogsDD2.reduce((a, b) => a + b.sum, 0) * -1 +
          stockMovements2.reduce((a, b) => a + b, 0) * -1)),
    ];

    otherIncome.data = [
        (otherIncomeDD.reduce(( a, b) => a + b.sum, 0)) * -1,
        (otherIncomeDD2.reduce(( a, b) => a + b.sum, 0)) * -1,
    ];

    cost.data = [
        ((cogsDD.reduce(( a, b) => a + b.sum, 0))) + (stockBalance1),
        ((cogsDD2.reduce(( a, b) => a + b.sum, 0))) + (stockBalance2),
    ];

    expenses.data = [
        (expensesDD.reduce(( a, b) => a + b.sum, 0)),
        (expensesDD2.reduce(( a, b) => a + b.sum, 0)),
    ];

    netProfit.data = [
      (grossProfit.data[0] + otherIncome.data[0] - expenses.data[0]),
      (grossProfit.data[1] + otherIncome.data[1] - expenses.data[1]),
    ];

    forex.data = [
      (Math.ceil(
          extraIncomeDD.reduce((a, b) => a + b.sum, 0)) * -1),
      (Math.ceil(
          extraIncomeDD2.reduce((a, b) => a + b.sum, 0)) * -1),
    ];

    npAfterEO.data = [
      (Math.ceil(netProfit.data[0]))
      + (Math.ceil(extraIncomeDD.reduce((a, b) => a + b.sum, 0)) * -1),
      (Math.ceil(netProfit.data[1]))
      + (Math.ceil(extraIncomeDD2.reduce((a, b) => a + b.sum, 0)) * -1),
    ];

    this.xAxisData = [
      (moment(this.currentYearRange[1]).year().valueOf()).toString(),
      (moment(this.pastYearRange[1]).year().valueOf()).toString(),
    ];

    this.series = [grossSales, cost, grossProfit, otherIncome, expenses, forex, npAfterEO];

    this.tableFields = [
      { key: 'date_range', sortable: false },
      {
        key: 'gross_sales',
        sortable: false,
        formatter: TableItemFormatter.currency,
      },
      {
        key: 'net_COGS',
        sortable: false,
        formatter: TableItemFormatter.currency,
      },
      {
        key: 'g._p.',
        sortable: false,
        formatter: TableItemFormatter.currency,
      },
      {
        key: 'g._p.%',
        sortable: false,
        formatter: TableItemFormatter.percentage,
      },
      {
        key: 'other_income',
        sortable: false,
        formatter: TableItemFormatter.currency,
      },
      {
        key: 'expenses',
        sortable: false,
        formatter: TableItemFormatter.currency,
      },
      {
        key: 'net_profit',
        sortable: false,
        formatter: TableItemFormatter.currency,
      },
      {
        key: 'n._p.%',
        sortable: false,
        formatter: TableItemFormatter.percentage,
      },
      {
        key: 'Realised_/_Unrealised_forex',
        sortable: false,
        formatter: TableItemFormatter.currency,
      },
      {
        key: 'np_after_eo',
        sortable: false,
        formatter: TableItemFormatter.currency,
      },
      {
        key: 'n._p.%_*',
        sortable: false,
        formatter: TableItemFormatter.percentage,
      },
    ];

    this.tableItems = [
      {
        'date_range': moment(this.currentYearRange[1]).year().valueOf(),
        'gross_sales': Math.ceil(grossSales.data[0]),
        'net_COGS': Math.ceil(cost.data[0]),
        'g._p.': Math.ceil(grossProfit.data[0]),
        'g._p.%': Math.ceil(grossProfit.data[0] / grossSales.data[0] * 100),
        'other_income': Math.ceil(otherIncome.data[0]),
        'expenses': Math.ceil(expenses.data[0]),
        'net_profit': Math.ceil(netProfit.data[0]),
        'n._p.%': Math.ceil(netProfit.data[0] / grossSales.data[0] * 100),
        'Realised_/_Unrealised_forex': Math.ceil(extraIncomeDD.reduce((a, b) => a + b.sum, 0)) * -1,
        'np_after_eo': (Math.ceil(netProfit.data[0]))
            + (Math.ceil(extraIncomeDD.reduce((a, b) => a + b.sum, 0)) * -1),
        'n._p.%_*': (Math.ceil(netProfit.data[0] +
            + (Math.ceil(extraIncomeDD.reduce((a, b) => a + b.sum, 0)) * -1))
            / grossSales.data[0] * 100),
      },
      {
        'date_range': moment(this.pastYearRange[1]).year().valueOf(),
        'gross_sales': Math.ceil(grossSales.data[1]),
        'net_COGS': Math.ceil(cost.data[1]),
        'g._p.': Math.ceil(grossProfit.data[1]),
        'g._p.%': Math.ceil(grossProfit.data[1] / grossSales.data[1] * 100),
        'other_income': Math.ceil(otherIncome.data[1]),
        'expenses': Math.ceil(expenses.data[1]),
        'net_profit': Math.ceil(netProfit.data[1]),
        'n._p.%': Math.ceil(netProfit.data[1] / grossSales.data[1] * 100),
        'Realised_/_Unrealised_forex': Math.ceil(extraIncomeDD2.reduce((a, b) => a + b.sum, 0)) * -1,
        'np_after_eo': (Math.ceil(netProfit.data[1]))
            + (Math.ceil(extraIncomeDD2.reduce((a, b) => a + b.sum, 0)) * -1),
        'n._p.%_*': (Math.ceil(netProfit.data[1] +
            + (Math.ceil(extraIncomeDD2.reduce((a, b) => a + b.sum, 0)) * -1))
            / grossSales.data[1] * 100),
      },
    ];

    this.saveHistory(
        'series',
        'series2',
        'xAxisData',
        'xAxisDate2',
        'tableFields',
        'tableItems',
    );
  }

}
