
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import Component from 'vue-class-component';
import moment, {MomentInput} from 'moment';
import {FilteredDatabase} from '@/worker/fd/FilteredDatabase';
import { TableItemFormatter } from '../../elements/charts/helpers/tableItemFormatter';
import TableCollapseToggle from '@/components/TableCollapseToggle.vue';

interface Totals {
    sub_total: number,
    total_discount: number,
    grand_total: number,
}

@Component({
    components: {
        TableCollapseToggle,
        TableFilterable,
    },
})
export default class TiarcoChemicalMOtherIncomeBreakdownTable extends FilterWidget {
    public filterIds: Array<
        | 'date'
        | 'month'
        | 'dateRange'
        | 'stockItems'
    > = ['dateRange', 'month', 'stockItems'];

    public tableItems: object[] = [];
    public tableFields: object[] = [];
    public periodTotalFields: any[] = [
        {
            key: 'sub_total',
            formatter: TableItemFormatter.currency,
        },
        {
            key: 'total_discount',
            formatter: TableItemFormatter.currency,
        },
        {
            key: 'grand_total',
            formatter: TableItemFormatter.currency,
        },
    ];
    public periodTotalData: Totals[] = [];
    public itemCode: string = '';
    public itemDesc: string = '';

    public exportName(range: number[]) {
        return 'NYCX Discount By Item Code' + ' from ' +
            moment(range[0] as MomentInput).format('DD MMM YY') +
            ' - ' +
            moment(range[1] as MomentInput).format('DD MMM YY');
    }

    public get generateTitleText() {
        return `Discount Table by Total`;
    }

    public get expensiveHook() {
        const {
            selectedDateRange,
        } = this;
        return JSON.stringify([
            selectedDateRange,
        ]);
    }

    public async expensiveCalc() {

        const ref = FilteredDatabase.ref('sales')
            .dateRange(this.selectedDateRange);

        const stockItemRef = FilteredDatabase.ref('globalStocks');

        const salesCards = await (await ref.get()).getCards();

        const stockCards = await(await stockItemRef.get()).getCards();

        const newArray: any = [];

        salesCards.forEach((card: {
            date: string | number,
            docNo: any;
            quantity: number;
            itemCode: string;
            itemDesc: string;
            amount: number;
            discount: any;
        }) => {
            const stockCard = stockCards.find((card1) => card1.itemCode === card.itemCode);
            const itemDesc = stockCard ? stockCard.itemDesc : null;
            newArray.push({
                date: moment(card.date).format('DD/MM/YY'),
                doc_no: card.docNo,
                item_code: card.itemCode,
                item_desc: itemDesc,
                qty: card.quantity,
                sub_total: Math.round(card.amount),
                disc: Math.round(card.discount),
                total: Math.round(card.amount - card.discount),
            });
        });

        this.tableItems = newArray;

        this.periodTotalData = [
            {
                sub_total: newArray.reduce((a: any, b: { sub_total: any; }) => a + b.sub_total, 0),
                total_discount: newArray.reduce((a: any, b: { disc: any; }) => a + b.disc, 0),
                grand_total: newArray.reduce((a: any, b: { total: any; }) => a + b.total, 0),
            },
        ];

        this.tableFields = [
            {
                key: 'date',
                sortable: false,
            },
            {
                key: 'doc_no',
                sortable: false,
            },
            {
                key: 'item_code',
                sortable: false,
            },
            {
                key: 'item_desc',
                sortable: false,
            },
            {
                key: 'qty',
                sortable: false,
                formatter: TableItemFormatter.basic,
            },
            {
                key: 'sub_total',
                sortable: false,
                formatter: TableItemFormatter.currency,
            },
            {
                key: 'disc',
                sortable: false,
                formatter: TableItemFormatter.currency,
            },
            {
                key: 'total',
                sortable: false,
                formatter: TableItemFormatter.currency,
            },

        ];
    }


}
